import { useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../RTTable'
import { LockerType } from '../../graphql/lockers/queryLockers'

interface ITableColumns {
  Header: string
  accessor:
    | 'building'
    | 'type'
    | 'appRental'
    | 'adminRental'
    | 'service'
    | 'available'
}
export interface ITableRow {
  building?: string
  type?: LockerType
  appRental: number
  adminRental: number
  service: number
  available: number
}

const LockerStatisticsTable: FC<{
  lockers: ITableRow[]
}> = ({ lockers }) => {
  const { t } = useTranslation(['lockers'])

  const csvHeaders = [
    {
      label: t('building'),
      key: 'building',
    },
    {
      label: t('locker_type'),
      key: 'type',
    },
    {
      label: t('app_rental'),
      key: 'appRental',
    },
    {
      label: t('admin_rental'),
      key: 'adminRental',
    },
    {
      label: t('service'),
      key: 'service',
    },
    {
      label: t('available'),
      key: 'available',
    },
  ]

  const tableColumns: ITableColumns[] = useMemo(() => {
    const handleTotal = (rows: any, value: any) =>
      rows.reduce((sum: number, row: any) => row.values[value] + sum, 0)
    return [
      {
        Header: t('building'),
        accessor: 'building',
        Footer: t('total_table'),
      },
      {
        Header: t('locker_type'),
        accessor: 'type',
        Cell: (props: { value: string }) => t(props.value),
      },
      {
        Header: t('app_rental'),
        accessor: 'appRental',
        Footer: (info: any) => {
          const total = useMemo(
            () => handleTotal(info.rows, 'appRental'),
            [info.rows]
          )
          return total
        },
      },
      {
        Header: t('admin_rental'),
        accessor: 'adminRental',
        Footer: (info: any) => {
          const total = useMemo(
            () => handleTotal(info.rows, 'adminRental'),
            [info.rows]
          )
          return total
        },
      },
      {
        Header: t('service'),
        accessor: 'service',
        Footer: (info: any) => {
          const total = useMemo(
            () => handleTotal(info.rows, 'service'),
            [info.rows]
          )
          return total
        },
      },
      {
        Header: t('available'),
        accessor: 'available',
        Footer: (info: any) => {
          const total = useMemo(
            () => handleTotal(info.rows, 'available'),
            [info.rows]
          )
          return total
        },
      },
    ]
  }, [t])

  const tableData: ITableRow[] = useMemo(() => {
    return (
      lockers.map(
        ({ building, type, appRental, adminRental, service, available }) => {
          return {
            building,
            type,
            appRental,
            adminRental,
            service,
            available,
          }
        }
      ) || []
    )
  }, [lockers])

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      rowSelect
      pagination
      csvExport
      csvHeaders={csvHeaders}
      isFiltersOff={true}
      orderByProps="building"
      sortDesc={false}
    />
  )
}

export default LockerStatisticsTable
