import gql from 'graphql-tag'

export interface IUpdateQuickPromoVars {
  id: string
  visible: boolean
}

export interface IUpdateQuickPromoData {
  updateQuickpromo: {
    quickPromo: {
      visible: boolean
      title: string
    }
  }
}

export const UPDATE_QUICK_PROMO_VISIBLE = gql`
  mutation updateQuickPromo($id: [ID!], $visible: Boolean) {
    updateQuickPromo(
      input: { filter: { id: $id }, set: { visible: $visible } }
    ) {
      quickPromo {
        id
        visible
        title
      }
    }
  }
`
