import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RTTable from '../../components/RTTable'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockerProductData,
  QUERY_LOCKER_PRODUCT,
} from '../../graphql/lockers/queryLockerProduct'
import { ILog } from '../../graphql/shared/sharedTypes'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import { Typography } from '@mui/material'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import EditLockerProduct from '../../components/Lockers/EditLockerProduct'
import ErrorContainer from '../../components/Shared/ErrorContainer'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'name'
    | 'buildingName'
    | 'periodName'
    | 'durationDays'
    | 'availability'
    | 'priceMessage'
    | 'price'
    | 'priceCSV'
}
export interface ITableRow {
  id: string
  name: string
  namePl: string
  nameEn: string
  buildingName: string
  building: {
    name: string
    id: string
  }
  periodName: string
  periodNamePl: string
  periodNameEn: string
  durationDays: number
  minAdvanceDays: number
  maxAdvanceDays: number
  availability: boolean
  priceMessage: string
  priceMessageEn: string
  price: string
  priceCSV: string
  rawPrice: number
  seller: string
  regulation: string
  logs?: ILog[]
  disabled: boolean
  withShower: boolean
}

const LockersTable = () => {
  const { t, i18n } = useTranslation(['locker_products'])
  const langPl = i18n.language === 'pl'
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [error, setError] = useState('')

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('product_name'),
      key: 'name',
    },
    {
      label: t('building'),
      key: 'buildingName',
    },
    {
      label: t('period'),
      key: 'periodName',
    },
    {
      label: t('days_number'),
      key: 'durationDays',
    },
    {
      label: t('availability'),
      key: 'availability',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const { data, refetch, loading } = useQuery<IQueryLockerProductData>(
    QUERY_LOCKER_PRODUCT,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: t('product_name'),
        accessor: 'name',
      },
      {
        Header: t('building'),
        accessor: 'buildingName',
      },
      {
        Header: t('period'),
        accessor: 'periodName',
      },
      {
        Header: t('days_number'),
        accessor: 'durationDays',
      },
      {
        Header: t('availability'),
        accessor: 'availability',
        Cell: (props: { value: boolean }) =>
          props.value ? t('unavailable') : t('available'),
      },
      {
        Header: t('price_name'),
        accessor: 'priceMessage',
      },
      {
        Header: t('price'),
        accessor: 'price',
      },
      {
        Header: t('price'),
        accessor: 'priceCSV',
        isVisible: false,
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.lockerProducts.map(
        ({
          id,
          name,
          nameEn,
          periodName,
          periodNameEn,
          disabled,
          regulation,
          type,
          building,
          durationDays,
          minAdvanceDays,
          maxAdvanceDays,
          price,
          priceMessage,
          priceMessageEn,
          seller,
          logs,
          withShower,
        }) => ({
          id,
          name: langPl ? name : nameEn,
          namePl: name,
          nameEn,
          buildingName: building.name,
          building,
          periodName: langPl ? periodName : periodNameEn,
          periodNamePl: periodName,
          periodNameEn,
          durationDays,
          minAdvanceDays,
          maxAdvanceDays,
          availability: disabled,
          priceMessage,
          priceMessageEn,
          price: formatPrice(price),
          priceCSV: formatToExcelPrice(price),
          seller: seller.name,
          regulation: regulation.id,
          rawPrice: price,
          type,
          logs,
          disabled,
          withShower,
        })
      ) || []
    )
  }, [data, langPl])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: ITableRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'availability'
            ? {
                style: {
                  color: !row.original.availability && themeColors.accept,
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditLockerProduct
          closeDetails={() => setOpenedDetails(undefined)}
          lockerProduct={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default LockersTable
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
