import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  QUERY_TENANT,
  IQueryTenantData,
} from '../../graphql/shared/queryTenant'
import BaseDrawer from '../Shared/BaseDrawer'
import ResidentRequestDetails from './ResidentRequestDetails'
import RTTable from '../RTTable'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { themeColors } from '../../const/colors'
import {
  QUERY_ISSUE,
  IQueryIssueVars,
  IQueryIssueData,
  newTenantIssuesFilter,
  resolvedTenantIssuesFilter,
} from '../../graphql/userIssues/queryIssue'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'fullName'
    | 'login'
    | 'email'
    | 'workEmail'
    | 'company'
    | 'createdAt'
    | 'createdAt_csv'
    | 'status'
    | 'statusTranslated'
    | 'closeAt'
    | 'closeAt_csv'
}

interface ITableRow {
  id: string
  fullName: string
  login: string
  email: string
  workEmail: string
  company: string
  createdAt: string
  createdAt_csv: string
  closeAt: string
  closeAt_csv: string
  status: string
  statusTranslated: string
  description: string
}

const getStatusColor = (status: string) => {
  return status === 'accepted' ? themeColors.accept : themeColors.error
}

const ResidentIssuesTable: FC<{
  tab: number
  updateAggregatedCount: () => void
}> = ({ tab, updateAggregatedCount }) => {
  const { t, i18n } = useTranslation([
    'columns',
    'admin_menu',
    'tenant_request_details',
  ])

  const [openedIssue, setOpenedIssue] = useState<ITableRow>()

  const {
    data: issues,
    loading: loadingIssues,
    refetch,
  } = useQuery<IQueryIssueData, IQueryIssueVars>(QUERY_ISSUE, {
    variables: {
      filter: tab ? resolvedTenantIssuesFilter : newTenantIssuesFilter,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    data: tenants,
    loading: loadingTenants,
    refetch: refetchTenants,
  } = useQuery<IQueryTenantData>(QUERY_TENANT, {
    fetchPolicy: 'no-cache',
  })

  const csvHeadersTab0 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('work_email'),
      key: 'workEmail',
    },
    {
      label: t('created_at'),
      key: 'createdAt_csv',
    },
    {
      label: 'Status',
      key: 'statusTranslated',
    },
  ]

  const csvHeadersTab1 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('work_email'),
      key: 'workEmail',
    },
    {
      label: t('close_date'),
      key: 'closeAt_csv',
    },
    {
      label: 'Status',
      key: 'statusTranslated',
    },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('full_name'),
        accessor: 'fullName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('login'),
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('main_email'),
        accessor: 'email',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('work_email'),
        accessor: 'workEmail',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('company'),
        accessor: 'company',
        isVisible: Boolean(tab),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'createdAt_csv',
        accessor: 'createdAt_csv',
        filter: 'filterRowsWithSubrows',
        isVisible: false,
      },
      {
        Header: t('close_date'),
        accessor: 'closeAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        isVisible: Boolean(tab),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'closeAt_csv',
        accessor: 'closeAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('status'),
        accessor: 'statusTranslated',
        isVisible: Boolean(tab),
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t, i18n, tab]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      issues?.queryIssue?.map(
        ({
          id,
          description,
          comment,
          status,
          createdAt,
          modifiedAt,
          user: { firstName, lastName, login, email, workEmail, tenant },
          tenantRequest: { code },
          resolvedBy,
        }) => ({
          id,
          fullName: `${firstName} ${lastName}`,
          login,
          email: email.email,
          workEmail: workEmail?.email || '',
          company: tenant?.name || '',
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          closeAt: modifiedAt,
          closeAt_csv: dateIso2ExcelFormat(modifiedAt),
          status,
          statusTranslated: t(`tenant_request_details:${status}`),
          description,
          code,
          comment,
          resolvedBy,
        })
      ) || [],
    [issues?.queryIssue, t]
  )

  if (loadingIssues || loadingTenants) {
    return <FullScreenLoader />
  }

  return (
    <>
      <RTTable
        columns={tableColumns}
        data={tableData}
        pagination
        csvExport
        rowSelect
        orderByProps={tab ? 'closeAt' : 'createdAt'}
        csvHeaders={tab ? csvHeadersTab1 : csvHeadersTab0}
        onRowClick={(rowData) => setOpenedIssue(rowData)}
        getCellProps={(cellInfo) =>
          cellInfo.column.id === 'statusTranslated'
            ? {
                style: {
                  color: getStatusColor(cellInfo.row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      <BaseDrawer open={!!openedIssue} variant={'temporary'}>
        <ResidentRequestDetails
          options={tenants?.queryTenant.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          issue={openedIssue}
          closeIssue={() => setOpenedIssue(undefined)}
          updateList={refetch}
          updateTenants={refetchTenants}
          updateAggregatedCount={updateAggregatedCount}
          tab={tab}
        />
      </BaseDrawer>
    </>
  )
}

export default ResidentIssuesTable
