import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AdvancedFilterInput from './AdvancedFilterInput'
import { BaseTextButton } from '../Shared/BaseButton'

const AdvancedColumnFilter = ({
  filters,
  setFilters,
  headers,
  login,
  setAllFilters,
}: {
  filters: any
  setFilters: any
  headers: any
  login?: string
  setAllFilters: any
}) => {
  const [visible, setVisible] = useState(!!login)
  const { t } = useTranslation('table_component')

  return (
    <WrapperColumn>
      <Row>
        {t('title')}{' '}
        <BaseTextButton
          onClick={() => setVisible(!visible)}
          data-e2e={'table-show-filters-button'}
        >
          {visible ? t('btn:collapse') : t('btn:expand')}
        </BaseTextButton>
      </Row>
      {visible && (
        <AdvancedFilterInput
          filterColumn={filters}
          setColumnFilter={setFilters}
          columns={headers}
          setAllFilters={setAllFilters}
        />
      )}
    </WrapperColumn>
  )
}

export default AdvancedColumnFilter

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  align-items: center;
`
const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`
