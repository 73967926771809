import gql from 'graphql-tag'

export interface IUpdateQuickPromoVars {
  id: string
  companyName: string
  companyLogo?: string
  title: string
  titleEn: string
  descriptionPl: string
  description: string
  instructionPl: string
  instruction: string
  locationPl: string
  location: string
  validFrom?: string
  validTo?: string
  eventId?: string
  hasDiscount: boolean
  discount?: string
  remove?: {
    valid_from?: string
    valid_to?: string
  }
}

export interface IUpdateQuickPromoData {
  updateQuickPromo: {
    quickPromo: {
      visible: boolean
      companyName: string
      companyLogo: string
      title: string
      titleEn: string
      description: string
      descriptionEn: string
      instruction?: string
      instructionEn?: string
      location: string
      locationEn: string
      validFrom: string
      validTo: string
      sort: number
      eventId?: string
      hasDiscount: boolean
      discount: string
    }
  }
}

export const UPDATE_QUICK_PROMO = gql`
  mutation updateQuickPromo(
    $id: [ID!]
    $companyName: String!
    $companyLogo: String
    $title: String!
    $titleEn: String!
    $descriptionPl: String
    $description: String
    $instructionPl: String
    $instruction: String
    $eventId: String
    $hasDiscount: Boolean
    $locationPl: String
    $location: String
    $validFrom: DateTime
    $validTo: DateTime
    $discount: String
    $remove: QuickPromoPatch
  ) {
    updateQuickPromo(
      input: {
        filter: { id: $id }
        set: {
          company_name: $companyName
          company_logo: $companyLogo
          title: $title
          title_en: $titleEn
          description_pl: $descriptionPl
          description: $description
          instruction_pl: $instructionPl
          instruction: $instruction
          event_id: $eventId
          has_discount: $hasDiscount
          location_pl: $locationPl
          location: $location
          valid_from: $validFrom
          valid_to: $validTo
          discount_info: $discount
        }
        remove: $remove
      }
    ) {
      quickPromo {
        id
        visible
        companyName: company_name
        companyLogo: company_logo
        title
        titleEn: title_en
        description: description_pl
        descriptionEn: description
        instruction: instruction_pl
        instructionEn: instruction
        location: location_pl
        locationEn: location
        validFrom: valid_from
        validTo: valid_to
        sort
        eventId: event_id
        hasDiscount: has_discount
        discount: discount_info
      }
    }
  }
`
