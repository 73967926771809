import gql from 'graphql-tag'

export type GardenTicketStatusType =
  | 'new'
  | 'reserved'
  | 'active'
  | 'active_outside'
  | 'cancelled'
  | 'past'
  | 'past_overridden'
  | 'unused'
  | 'unavailable'
  | 'error'

export type GardenOrderStatusType =
  | 'new'
  | 'received'
  | 'processing'
  | 'confirmed'
  | 'completed'
  | 'failed'
  | 'error'

export interface IGardenTicket {
  id: string
  status: GardenTicketStatusType
  name: string
  nameEn: string
  qrCode: string
  createdAt: string
  validFrom: string
  order?: {
    orderId: string
    orderStatus: GardenOrderStatusType
  }
  user?: {
    login: string
    firstName: string
    lastName: string
    email: { email: string } | null
    workEmail: { email: string } | null
    tenant: { name: string }
  }
  price: number
  residentPromo: boolean
}
export interface IQueryGardenTicketData {
  queryGardenTicket: IGardenTicket[]
}

export const QUERY_GARDEN_TICKETS = gql`
  query queryGardenTicket {
    queryGardenTicket(filter: { has: order }) {
      id
      status
      name
      nameEn: name_en
      qrCode: qr_code
      createdAt: created_at
      validFrom: valid_from
      order {
        orderId: id
        orderStatus: status
      }
      user {
        login
        firstName: first_name
        lastName: last_name
        email {
          email
        }
        workEmail: work_email {
          email
        }
        tenant {
          name
        }
      }
      price
      residentPromo: resident_promo
    }
  }
`
export const OTHER_GARDEN_TICKETS = gql`
  query queryGardenTicket {
    queryGardenTicket(filter: { not: { has: order } }) {
      id
      status
      name
      nameEn: name_en
      qrCode: qr_code
      createdAt: created_at
      validFrom: valid_from
      user {
        login
        firstName: first_name
        lastName: last_name
        email {
          email
        }
        workEmail: work_email {
          email
        }
        tenant {
          name
        }
      }
      price
      residentPromo: resident_promo
    }
  }
`
