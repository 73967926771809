import React from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  QUERY_ORIGO_CARD,
  IQueryOrigoCardData,
} from '../../graphql/hid/queryOrigoCard'
import { useQuery } from '@apollo/client'
import MobileAccessCardTable from '../../components/HID/MobileAccessCardTable'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'

const MobileAccessCardList = () => {
  const { t } = useTranslation(['skd'])

  const { data, loading, refetch } =
    useQuery<IQueryOrigoCardData>(QUERY_ORIGO_CARD)

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('access_card_list')}</TypographyStyled>
      <MobileAccessCardTable data={data?.queryOrigoCard} refetch={refetch} />
    </>
  )
}

export default MobileAccessCardList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
