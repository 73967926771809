import { useState } from 'react'
import styled from 'styled-components'
import { ClickAwayListener } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  ADD_QUICK_PROMO,
  IAddQuickPromoData,
  IAddQuickPromoVars,
} from '../../graphql/quickPromo/addQuickPromo'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'
import QuickPromoInputs from './QuickPromoInputs'
import { date2isoString } from '../../utils/formatDate'
import { ExtrasType } from './ExtrasTypeRadio'

type FormData = {
  companyName: string
  companyLogo: {
    value: string
    label: string
  }
  title: string
  titleEn: string
  discount?: string
  descriptionPl: string
  description: string
  instructionPl: string
  instruction: string
  locationPl: string
  location: string
  dateFrom?: Date
  dateTo?: Date
  timeFrom?: Date
  timeTo?: Date
  eventId?: {
    value: string
    label: string
  }
  hasDiscount: boolean
  sort: number
}

const AddQuickPromo = ({
  refetch,
  closeDetails,
  itemsAmount,
}: {
  refetch: () => void
  closeDetails: () => void
  itemsAmount: number
}) => {
  const { t } = useTranslation(['promo'])
  const [extrasType, setExtrasType] = useState<ExtrasType>('fixed')
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)
  const createDate = (date?: any, time?: any) => {
    let createdDate = date ? new Date(date) : undefined
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    if (date && time) {
      createdDate?.setHours(hours)
      createdDate?.setMinutes(minutes)
    }
    return createdDate ? date2isoString(createdDate) : undefined
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>()
  const watchFormData = useWatch({ control })

  const onSubmit = (formData: FormData) => {
    const {
      companyName,
      companyLogo,
      title,
      titleEn,
      discount,
      description,
      descriptionPl,
      instruction,
      instructionPl,
      location,
      locationPl,
      eventId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
    } = formData

    const validFrom = createDate(dateFrom, timeFrom)
    const validTo = createDate(dateTo, timeTo)

    addQuickPromo({
      variables: {
        companyName,
        companyLogo: companyLogo ? companyLogo.value : '',
        title,
        titleEn,
        description,
        descriptionPl,
        instruction,
        instructionPl,
        location,
        locationPl,
        eventId:
          extrasType === 'event' && eventId ? eventId.value.toString() : '',
        hasDiscount: extrasType === 'fixed',
        validFrom,
        validTo,
        sort: itemsAmount + 1,
        discount,
      },
    })
  }

  const onCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const [addQuickPromo] = useMutation<IAddQuickPromoData, IAddQuickPromoVars>(
    ADD_QUICK_PROMO,
    {
      onCompleted,
      fetchPolicy: 'no-cache',
    }
  )

  const handleClickAway = () => {
    !!Object.keys(watchFormData).length
      ? setOpenClickAwayModal(true)
      : closeDetails()
  }

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('add_promo')} handleClose={handleClickAway} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <QuickPromoInputs
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              extrasType={extrasType}
              setExtrasType={setExtrasType}
            />
            <Buttons
              handleAccept={handleSubmit(onSubmit)}
              handleCancel={handleClickAway}
            />
          </form>
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default AddQuickPromo

const Buttons = styled(DrawerButtons)`
  margin: 16px;
`
