import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import {
  IPlace,
  ParkingPlaceStatus,
} from '../../graphql/parking/queryParkingPlace'
import { ILog } from '../../graphql/shared/sharedTypes'
import { getParkingPlaceStatusColor } from '../../utils/getStatusColor'
import { Checkbox, FormControlLabel } from '@mui/material'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'parking'
    | 'level'
    | 'place'
    | 'shared'
    | 'status'
    | 'leaseTerm'
    | 'validFromCSV'
    | 'validToCSV'
    | 'company'
    | 'tenant'
}
export interface ITableRow {
  id: string
  parking?: string
  level?: string
  place: string
  shared: string
  status: ParkingPlaceStatus
  leaseTerm: string
  validFromCSV: string
  validToCSV: string
  company: string
  tenant?: string
  info: {
    name: string
    message: string
  }
  building: string
  logs: ILog[]
}

const PlacesTable = ({
  places,
  children,
  setSelectedPlaces,
}: {
  places: IPlace[]
  children: ReactNode
  setSelectedPlaces: (v: ITableRow[]) => void
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const [hideNumbers, setHideNumbers] = useState(false)

  const lang = i18n.language

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: t('level'),
      key: 'level',
    },
    {
      label: t('place'),
      key: 'place',
    },
    {
      label: t('shared'),
      key: 'shared',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('from'),
      key: 'validFromCSV',
    },
    {
      label: t('to'),
      key: 'validToCSV',
    },
    {
      label: t('company'),
      key: 'company',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
  ]

  const handleSelectRows = useCallback((selectedRowsData: ITableRow[]) => {
    setSelectedPlaces(selectedRowsData)
  }, [])

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: 'Parking',
        accessor: 'parking',
      },
      {
        Header: t('level'),
        accessor: 'level',
      },
      {
        Header: t('place'),
        accessor: 'place',
        isVisible: !hideNumbers,
      },
      {
        Header: t('shared'),
        accessor: 'shared',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: { value: string }) => t(props.value),
      },
      {
        Header: t('lease_term'),
        accessor: 'leaseTerm',
      },
      {
        Header: t('from'),
        accessor: 'validFromCSV',
        isVisible: false,
      },
      {
        Header: t('to'),
        accessor: 'validToCSV',
        isVisible: false,
      },
      {
        Header: t('company'),
        accessor: 'company',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
      },
    ],
    [t, hideNumbers]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      places.map(
        ({
          id,
          name,
          status,
          parking,
          level,
          building,
          tenant,
          info,
          assignedTo,
          shared,
          reservations,
          logs,
        }) => ({
          id,
          parking: parking?.name,
          level: level?.name,
          place: name,
          shared: shared ? t('generic:yes') : t('generic:no'),
          status,
          leaseTerm: !!reservations[0]
            ? `${dateIso2localeString(reservations[0].validFrom, lang, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })} - ${dateIso2localeString(reservations[0].validTo, lang, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}`
            : '-',
          validFromCSV:
            !!reservations[0] && dateIso2ExcelFormat(reservations[0].validFrom),
          validToCSV:
            !!reservations[0] && dateIso2ExcelFormat(reservations[0].validTo),
          company: tenant?.name || '-',
          tenant: assignedTo
            ? `${assignedTo.firstName} ${assignedTo.lastName}`
            : '-',
          building: building?.name,
          info,
          logs,
        })
      ) || []
    )
  }, [places, lang, t])

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      rowSelect
      pagination
      csvExport
      isFiltersOff={true}
      onSelectionChange={handleSelectRows}
      csvHeaders={csvHeaders}
      orderByProps="parking"
      sortDesc={false}
      getCellProps={({ column, row }) =>
        column.id === 'status'
          ? {
              style: {
                color: getParkingPlaceStatusColor(row.original.status),
                fontWeight: 600,
              },
            }
          : {}
      }
    >
      {children}
      <FormControlLabel
        control={
          <Checkbox
            checked={hideNumbers}
            onChange={() => setHideNumbers(!hideNumbers)}
          />
        }
        label={`${t('hide_numbers')}`}
      />
    </RTTable>
  )
}

export default PlacesTable
