import gql from 'graphql-tag'

export interface IUpdateQuickPromoSortVars {
  id: string
  sort: number
}

export interface IUpdateQuickPromoSortData {
  updateQuickPromo: {
    quickPromo: {
      sort: number
      title: string
    }
  }
}

export const UPDATE_QUICK_PROMO_SORT = gql`
  mutation updateQuickPromo($id: [ID!], $sort: Int) {
    updateQuickPromo(input: { filter: { id: $id }, set: { sort: $sort } }) {
      quickPromo {
        id
        sort
        title
      }
    }
  }
`
