import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { TextBody1Medium } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import DrawerButtons from '../Shared/DrawerButtons'
import { IQuickPromo } from '../../graphql/quickPromo/queryQuickPromo'
import {
  UPDATE_QUICK_PROMO_SORT,
  IUpdateQuickPromoSortData,
  IUpdateQuickPromoSortVars,
} from '../../graphql/quickPromo/updateQuickPromoSort'
import FullScreenLoader from '../Shared/FullScreenLoader'
import DrawerHeader from '../Shared/DrawerHeader'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { themeColors } from '../../const/colors'
import { toast } from 'react-toastify'

const SortQuickPromo = ({
  data,
  refetch,
  closeDetails,
}: {
  data?: IQuickPromo[]
  refetch: () => void
  closeDetails: () => void
}) => {
  const { t } = useTranslation(['promo'])
  const [newOrder, setNewOrder] = useState<IQuickPromo[]>()
  const successNotify = (message: string) => toast.dark(message)

  const ReorderItems = (
    list: IQuickPromo[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const [updateQuickPromo, { loading }] = useMutation<
    IUpdateQuickPromoSortData,
    IUpdateQuickPromoSortVars
  >(UPDATE_QUICK_PROMO_SORT, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    data && setNewOrder(data)
  }, [data])

  if (!data || !newOrder) {
    return null
  }

  if (loading) {
    return <FullScreenLoader />
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    if (result.destination.index === result.source.index) {
      return
    }
    const _newOrder =
      newOrder &&
      ReorderItems(newOrder, result.source.index, result.destination.index)
    setNewOrder(_newOrder)
  }

  const handleSubmit = async () => {
    await newOrder.forEach((item, i) => {
      updateQuickPromo({ variables: { id: item.id, sort: i + 1 } })
    })
    successNotify(`${t('generic:success_message')}`)
  }

  const handleClose = () => {
    refetch()
    closeDetails()
  }

  return (
    <Wrapper>
      <DrawerHeader title={t('set_order')} handleClose={handleClose} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {newOrder.map((promo, index) => (
                <Draggable draggableId={promo.id} index={index} key={promo.id}>
                  {(provided) => (
                    <DraggableItem
                      data-e2e={`draggable-${index}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TextBody1Medium color={themeColors.primary}>
                        {promo.title}
                      </TextBody1Medium>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Buttons handleAccept={handleSubmit} handleCancel={handleClose} />
    </Wrapper>
  )
}

export default SortQuickPromo

const Wrapper = styled(Grid)`
  flex: 1;
`
const Buttons = styled(DrawerButtons)`
  margin: 16px;
`
const DraggableItem = styled.div`
  width: 100%;
  border: 2px solid ${themeColors.primary};
  border-radius: 10px;
  margin-bottom: 16px;
  background-color: ${themeColors.white};
  padding: 0 16px;
`
