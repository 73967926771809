import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import ParkingsListTable from '../../components/Parking/ParkingListTable'
import styled from 'styled-components'

const ParkingsList = () => {
  const { t } = useTranslation(['parking_list'])

  return (
    <>
      <Grid container>
        <Grid xs={6} item>
          <TypographyStyled variant="h6">{t('parking_list')}</TypographyStyled>
        </Grid>
      </Grid>
      <ParkingsListTable />
    </>
  )
}

export default ParkingsList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
