import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseDrawer from '../Shared/BaseDrawer'
import RTTable from '../RTTable'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import UserMessageDetails from './UserMessageDetails'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import {
  QUERY_ISSUE,
  IQueryIssueVars,
  IQueryIssueData,
  newMessageIssuesFilter,
  resolvedMessageIssuesFilter,
  IssueType,
} from '../../graphql/userIssues/queryIssue'
import { useParams } from 'react-router-dom'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'fullName'
    | 'login'
    | 'email'
    | 'message'
    | 'reply'
    | 'createdAt'
    | 'createdAt_csv'
    | 'closeAt'
    | 'closeAt_csv'
    | 'company'
}
export interface ITableRow {
  id: string
  fullName: string
  login: string
  email: string
  message: string
  reply: string
  createdAt: string
  createdAt_csv: string
  closeAt: string
  closeAt_csv: string
  company?: string
  disabled: boolean
  os?: string
  app: string
  apiVersion: number
  backendVersion: string
  type: IssueType
  resolvedBy: {
    login: string
    firstName: string
    lastName: string
  }
}

const ResidentIssues: FC<{
  tab: number
  updateAggregatedCount: () => void
}> = ({ tab, updateAggregatedCount }) => {
  const { t, i18n } = useTranslation(['columns', 'admin_menu'])
  const [openedIssue, setOpenedIssue] = useState<ITableRow>()
  const { usr } = useParams()
  const {
    data: issues,
    loading: loadingIssues,
    refetch,
  } = useQuery<IQueryIssueData, IQueryIssueVars>(QUERY_ISSUE, {
    variables: {
      filter: tab ? resolvedMessageIssuesFilter : newMessageIssuesFilter,
    },
    fetchPolicy: 'no-cache',
  })

  const messageStyles = (text: string) => <MessageCell>{text}</MessageCell>

  const csvHeadersTab0 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('message'),
      key: 'message',
    },
    {
      label: t('created_at'),
      key: 'createdAt_csv',
    },
  ]

  const csvHeadersTab1 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('message'),
      key: 'message',
    },
    {
      label: t('reply'),
      key: 'reply',
    },
    {
      label: t('created_at'),
      key: 'createdAt_csv',
    },
    {
      label: t('close_date'),
      key: 'closeAt_csv',
    },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('full_name'),
        accessor: 'fullName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('login'),
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('main_email'),
        accessor: 'email',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('message'),
        accessor: 'message',
        Cell: (cellInfo: any) => messageStyles(cellInfo.row.original.message),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('reply'),
        accessor: 'reply',
        isVisible: Boolean(tab),
        Cell: (cellInfo: any) => messageStyles(cellInfo.row.original.reply),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'createdAt_csv',
        accessor: 'createdAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('close_date'),
        accessor: 'closeAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        isVisible: Boolean(tab),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'closeAt_csv',
        accessor: 'closeAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t, i18n, tab]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      issues?.queryIssue.map(
        ({
          id,
          description,
          createdAt,
          modifiedAt,
          comment,
          formName,
          type,
          os,
          app,
          apiVersion,
          backendVersion,
          user: { firstName, login, lastName, email, tenant, disabled },
          resolvedBy,
        }) => ({
          id,
          fullName: `${firstName} ${lastName}`,
          login: login,
          email: email.email,
          message: description,
          reply: comment,
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          closeAt: modifiedAt,
          closeAt_csv: dateIso2ExcelFormat(modifiedAt),
          company: tenant?.name,
          resolvedBy,
          formName,
          type,
          os,
          app,
          apiVersion,
          backendVersion,
          disabled,
        })
      ) || [],
    [issues?.queryIssue]
  )

  if (loadingIssues) {
    return <FullScreenLoader />
  }

  return (
    <>
      <RTTable
        columns={tableColumns}
        data={tableData}
        onRowClick={(rowData) => setOpenedIssue(rowData)}
        pagination
        csvExport
        rowSelect
        orderByProps={tab ? 'closeAt' : 'createdAt'}
        csvHeaders={tab ? csvHeadersTab1 : csvHeadersTab0}
        loginFilter={usr}
      />
      <BaseDrawer open={!!openedIssue} variant={'temporary'}>
        {openedIssue && (
          <UserMessageDetails
            issue={openedIssue}
            closeIssue={() => setOpenedIssue(undefined)}
            updateList={refetch}
            updateAggregatedCount={updateAggregatedCount}
            tab={tab}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default ResidentIssues

const MessageCell = styled(Grid)`
  max-height: 60px;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
`
