import React, { useState } from 'react'
import { Typography, Tab } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import MobileAccessCardIssuesTable from '../../components/HID/MobileAccessCardIssuesTable'
import {
  BadgeStyled,
  TabsStyled,
} from '../../components/Styles/CustomElementsStyled'

const MobileAccessCardIssuesList = ({
  accessCardIssuesCount,
  refetchAccessCardIssuesCount,
}: {
  accessCardIssuesCount?: number
  refetchAccessCardIssuesCount: () => void
}) => {
  const [value, setValue] = useState(0)
  const { t } = useTranslation(['skd'])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <BadgeStyled badgeContent={accessCardIssuesCount} color="error">
        <TypographyStyled variant="h6">
          {t('access_confirmation_requests')}
        </TypographyStyled>
      </BadgeStyled>
      <TabsStyled value={value} onChange={handleChange} textColor="primary">
        <Tab label={t('new')} data-e2e="messages-tab-open" />
        <Tab label={t('closed')} data-e2e="messages-tab-close" />
      </TabsStyled>
      <MobileAccessCardIssuesTable
        tab={value}
        updateAggregatedCount={refetchAccessCardIssuesCount}
      />
    </>
  )
}

export default MobileAccessCardIssuesList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
