import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import RTTable from '../RTTable'
import { IPlace } from '../../graphql/parking/queryParkingPlaceReservations'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { ILog } from '../../graphql/shared/sharedTypes'
import { getReservationStatusColor } from '../../utils/getStatusColor'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { TextBody1Bold } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'
import { BaseButton } from '../Shared/BaseButton'
import { getCurrentReservation } from '../../utils/getCurrentReservation'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'parking'
    | 'level'
    | 'place'
    | 'shared'
    | 'status'
    | 'leaseTerm'
    | 'validFromCSV'
    | 'validToCSV'
    | 'company'
    | 'tenant'
}
export interface ITableRow {
  id: string
  parking?: string
  level?: string
  place: string
  shared: string
  status: ReservationStatus
  leaseTerm: string
  validFromCSV?: string
  validToCSV?: string
  company: string
  tenant?: string
  info: {
    name: string
    message: string
  }
  building: string
  logs: ILog[]
}

const PlacesTable = ({
  places,
  children,
  status,
  rangeFrom,
  setOpenedEditForm,
}: {
  places: IPlace[]
  children: ReactNode
  status: ReservationStatus | ''
  rangeFrom: Date | null
  setOpenedEditForm: (v: ITableRow[]) => void
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const [hideNumbers, setHideNumbers] = useState(false)
  const [selectedPlaces, setSelectedPlaces] = useState<ITableRow[]>([])

  const lang = i18n.language

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: t('level'),
      key: 'level',
    },
    {
      label: t('place'),
      key: 'place',
    },
    {
      label: t('shared'),
      key: 'shared',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('from'),
      key: 'validFromCSV',
    },
    {
      label: t('to'),
      key: 'validToCSV',
    },
    {
      label: t('company'),
      key: 'company',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
  ]

  const handleSelectRows = useCallback((selectedRowsData: ITableRow[]) => {
    setSelectedPlaces(selectedRowsData)
  }, [])

  const handleOpenEditForm = () => setOpenedEditForm(selectedPlaces)

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: 'Parking',
        accessor: 'parking',
      },
      {
        Header: t('level'),
        accessor: 'level',
      },
      {
        Header: t('place'),
        accessor: 'place',
        isVisible: !hideNumbers,
      },
      {
        Header: t('shared'),
        accessor: 'shared',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: { value: string }) =>
          t(`reservation_status:${props.value}`),
      },
      {
        Header: t('lease_term'),
        accessor: 'leaseTerm',
      },
      {
        Header: t('from'),
        accessor: 'validFromCSV',
        isVisible: false,
      },
      {
        Header: t('to'),
        accessor: 'validToCSV',
        isVisible: false,
      },
      {
        Header: t('company'),
        accessor: 'company',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
      },
    ],
    [t, hideNumbers]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      places.map(
        ({
          id,
          name,
          parking,
          level,
          building,
          tenant,
          info,
          assignedTo,
          shared,
          reservations,
          logs,
        }) => {
          const currentReservation = getCurrentReservation(reservations)
          return {
            id,
            parking: parking?.name,
            level: level?.name,
            place: name,
            shared: shared ? t('generic:yes') : t('generic:no'),
            status: currentReservation
              ? currentReservation.status
              : ReservationStatus.available,
            leaseTerm: currentReservation
              ? `${dateIso2localeString(currentReservation.validFrom, lang, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })} - ${dateIso2localeString(currentReservation.validTo, lang, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}`
              : '-',
            validFromCSV:
              currentReservation &&
              dateIso2ExcelFormat(currentReservation.validFrom),
            validToCSV:
              currentReservation &&
              dateIso2ExcelFormat(currentReservation.validTo),
            company: tenant?.name || '-',
            tenant: assignedTo
              ? `${assignedTo.firstName} ${assignedTo.lastName}`
              : '-',
            building: building?.name,
            info,
            logs,
          }
        }
      ) || []
    )
  }, [places, lang, t])

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      rowSelect
      pagination
      csvExport
      isFiltersOff={true}
      orderByProps="parking"
      sortDesc={false}
      onSelectionChange={handleSelectRows}
      csvHeaders={csvHeaders}
      getCellProps={({ column, row }) =>
        column.id === 'status'
          ? {
              style: {
                color: getReservationStatusColor(row.original.status),
                fontWeight: 600,
              },
            }
          : {}
      }
    >
      {children}
      <FormControlLabel
        control={
          <Checkbox
            checked={hideNumbers}
            onChange={() => setHideNumbers(!hideNumbers)}
          />
        }
        label={`${t('hide_numbers')}`}
      />
      {!!selectedPlaces.length && (
        <Row>
          <TextBody1Bold color={themeColors.primary}>{`${
            selectedPlaces.length
          } ${t('generic:selected')}`}</TextBody1Bold>
          {status && rangeFrom && (
            <ButtonStyled onClick={handleOpenEditForm}>
              {t('change_status')}
            </ButtonStyled>
          )}
        </Row>
      )}
    </RTTable>
  )
}

export default PlacesTable

const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
`
const ButtonStyled = styled(BaseButton)`
  margin-left: 16px;
`
