export const themeColors = {
  primary: '#4176A4',
  lightPrimary: '#538ABA',
  secondary: '#7FA8CC',
  lightBlue: '#E8F3FB',
  backgroundBox: '#E8F3FB',
  background: '#FFFEFB',
  error: '#FF0C3E',
  accept: '#0D8612',
  white: '#FFFFFF',
  lightGray: '#BDBDBD',
  lighterGray: '#E1E1E1',
  paleGray: '#F1F1F1',
  gray: '#888888',
  gray1: '#333333',
  gray3: '#828282',
  gray4: '#535353',
  black: '#1D1B1B',
  blackInactive: 'rgba(0, 0, 0, 0.54)',
  blackHighEmphasis: 'rgba(0, 0, 0, 0.87)',
  garden: '#19A35F',
  primaryDark: '#0C2054',
  disabled: '#DADADA',
  yellow: '#F1901D',
  negative: '#EB5757',
  negativeHover: '#B91616',
  lightNegative: '#FEF3F3',
  success: '#219653',
  successHover: '#186C3C',
  darkBlue: '#133649',
}
