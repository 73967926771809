import { FC, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseDrawer from '../Shared/BaseDrawer'
import RTTable from '../RTTable'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import IssueDetails from './IssueDetails'
import {
  QUERY_ISSUE,
  IQueryIssueVars,
  IQueryIssueData,
  newCardAccessIssuesFilter,
  resolvedCardAccessIssuesFilter,
  IssueType,
  IssueStatus,
} from '../../graphql/userIssues/queryIssue'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'fullName'
    | 'login'
    | 'email'
    | 'message'
    | 'reply'
    | 'createdAt'
    | 'createdAt_csv'
    | 'closeAt'
    | 'closeAt_csv'
    | 'company'
}
export interface ITableRow {
  id: string
  fullName: string
  login: string
  email: string
  message: string
  reply: string
  createdAt: string
  createdAt_csv: string
  closeAt: string
  closeAt_csv: string
  company?: string
  disabled: boolean
  os?: string
  app: string
  apiVersion: number
  backendVersion: string
  type: IssueType
  resolvedBy: {
    login: string
    firstName: string
    lastName: string
  }
  status: IssueStatus
}

const MobileAccessCardIssuesTable: FC<{
  tab: number
  updateAggregatedCount: () => void
}> = ({ tab, updateAggregatedCount }) => {
  const { t, i18n } = useTranslation(['columns', 'admin_menu'])
  const [openedIssue, setOpenedIssue] = useState<ITableRow>()
  const navigate = useNavigate()
  const params = useParams() as { issue: string }
  const {
    data: issues,
    loading: loadingIssues,
    refetch,
  } = useQuery<IQueryIssueData, IQueryIssueVars>(QUERY_ISSUE, {
    variables: {
      filter: tab ? resolvedCardAccessIssuesFilter : newCardAccessIssuesFilter,
    },
    fetchPolicy: 'no-cache',
  })

  const handleCloseDrawer = () => {
    setOpenedIssue(undefined)
    navigate('/admin/access-card-issues/:issue', { state: {}, replace: true })
  }

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('full_name'),
        accessor: 'fullName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('login'),
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('main_email'),
        accessor: 'email',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('tenant'),
        accessor: 'company',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'createdAt_csv',
        accessor: 'createdAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('close_date'),
        accessor: 'closeAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        isVisible: Boolean(tab),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'closeAt_csv',
        accessor: 'closeAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t, i18n, tab]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      issues?.queryIssue.map(
        ({
          id,
          description,
          createdAt,
          modifiedAt,
          comment,
          formName,
          type,
          os,
          app,
          apiVersion,
          backendVersion,
          user: { firstName, login, lastName, email, tenant, disabled },
          resolvedBy,
          status,
        }) => ({
          id,
          fullName: `${firstName} ${lastName}`,
          login: login,
          email: email.email,
          message: description,
          reply: comment,
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          closeAt: modifiedAt,
          closeAt_csv: dateIso2ExcelFormat(modifiedAt),
          company: tenant?.name,
          resolvedBy,
          formName,
          type,
          os,
          app,
          apiVersion,
          backendVersion,
          disabled,
          status,
        })
      ) || [],
    [issues?.queryIssue]
  )

  useEffect(() => {
    if (params.issue !== ':issue') {
      setOpenedIssue(tableData.find(({ id }) => id === params.issue))
    }
  }, [tableData])

  if (loadingIssues) {
    return <FullScreenLoader />
  }

  return (
    <>
      <RTTable
        columns={tableColumns}
        data={tableData}
        onRowClick={(rowData) => setOpenedIssue(rowData)}
        pagination
        csvExport
        rowSelect
        orderByProps="createdAt"
      />
      <BaseDrawer open={!!openedIssue} variant={'temporary'}>
        <IssueDetails
          issue={openedIssue}
          closeIssue={handleCloseDrawer}
          updateList={refetch}
          updateAggregatedCount={updateAggregatedCount}
          tab={tab}
        />
      </BaseDrawer>
    </>
  )
}

export default MobileAccessCardIssuesTable
