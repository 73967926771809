import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RTTable from '../../components/RTTable'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockerGroupReservationData,
  QUERY_LOCKER_GROUP_RESERVATION,
} from '../../graphql/lockers/queryLockerGroupReservation'
import { ILog } from '../../graphql/shared/sharedTypes'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { Typography } from '@mui/material'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { getReservationStatusColor } from '../../utils/getStatusColor'
import { LockerType } from '../../graphql/lockers/queryLockers'
import EditLockerGroupReservation from '../../components/LockerGroupReservations/EditLockerGroupReservation'
import ReservationTableActions from '../../components/LockerGroupReservations/ReservationTableActions'
import GroupReservationProtocolWrapper from '../../components/LockerGroupReservations/GroupReservationProtocolWrapper'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ADMIN } from '../../const/permissions'
import ErrorContainer from '../../components/Shared/ErrorContainer'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'building'
    | 'status'
    | 'garageLockers'
    | 'roomLockers'
    | 'period'
    | 'tenant'
    | 'price'
    | 'priceCSV'
    | 'createdAt'
    | 'validFrom'
    | 'validTo'
    | 'comment'
}
export interface ITableRow {
  id: string
  building: string
  status: ReservationStatus
  garageLockers: number
  roomLockers: number
  period: string
  tenant: string
  tenantId?: string
  tenantName: string
  tenantEmail: string
  price: number
  priceCSV: string
  createdAt: string
  validFrom: string
  validTo: string
  comment: string
  lockers: {
    name: string
    key: string
    transponder: string
    type: LockerType
  }[]
  logs: ILog[]
}

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const GroupReservationList = () => {
  const { t, i18n } = useTranslation(['locker_group_reservation'])
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [openedProtocol, setOpenedProtocol] = useState<ITableRow>()
  const [pickUpProtocol, setPickUpProtocol] = useState(false)
  const [error, setError] = useState('')
  const { roles } = useAppSelector((state) => state.user)

  const handleCloseProtocol = () => {
    setPickUpProtocol(false)
    setOpenedProtocol(undefined)
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('building'),
      key: 'building',
    },
    {
      label: t('valid_from'),
      key: 'validFrom',
    },
    {
      label: t('valid_to'),
      key: 'validTo',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('garage_lockers'),
      key: 'garageLockers',
    },
    {
      label: t('room_lockers'),
      key: 'roomLockers',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const { data, refetch, loading } = useQuery<IQueryLockerGroupReservationData>(
    QUERY_LOCKER_GROUP_RESERVATION,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: t('building'),
        accessor: 'building',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: { value: string }) =>
          t(`reservation_status:${props.value}`),
      },
      {
        Header: t('garage_lockers'),
        accessor: 'garageLockers',
      },
      {
        Header: t('room_lockers'),
        accessor: 'roomLockers',
      },
      {
        Header: t('period'),
        accessor: 'period',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
      },
      {
        Header: t('price'),
        accessor: 'price',
        Cell: (props: { value: string }) => formatPrice(Number(props.value)),
      },
      {
        Header: t('price'),
        accessor: 'priceCSV',
        isVisible: false,
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
      },
      {
        Header: t('validFrom'),
        accessor: 'validFrom',
        isVisible: false,
      },
      {
        Header: t('validTo'),
        accessor: 'validTo',
        isVisible: false,
      },
      {
        Header: t('comment'),
        accessor: 'comment',
        isVisible: false,
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.groupReservations.map(
        ({
          id,
          createdAt,
          validFrom,
          validTo,
          status,
          building,
          garageLockers,
          roomLockers,
          tenant,
          tenantName,
          tenantEmail,
          comment,
          price,
          logs,
          lockers,
        }) => ({
          id,
          createdAt: dateIso2localeString(createdAt, lang),
          validFrom: dateIso2ExcelFormat(validFrom),
          validTo: dateIso2ExcelFormat(validTo),
          period: `${dateIso2localeString(
            validFrom,
            lang,
            options
          )} - ${dateIso2localeString(validTo, lang, options)}`,
          status,
          building: building.name,
          garageLockers,
          roomLockers,
          tenant: tenant?.name ? tenant?.name : tenantName,
          tenantId: tenant?.id,
          tenantName,
          tenantEmail,
          comment,
          logs,
          price,
          priceCSV: formatToExcelPrice(price),
          lockers,
        })
      ) || []
    )
  }, [data, lang])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('group_reservations')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: ITableRow) => {
          checkRole(roles, LOCKER_ADMIN) && rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getReservationStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
        actionsComponent={(rowData: ITableRow) =>
          checkRole(roles, LOCKER_ADMIN) && (
            <ReservationTableActions
              data={rowData}
              setOpenedProtocol={setOpenedProtocol}
              setPickUpProtocol={setPickUpProtocol}
            />
          )
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditLockerGroupReservation
          closeDetails={() => setOpenedDetails(undefined)}
          reservation={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
      <BaseDrawer open={!!openedProtocol} variant={'temporary'}>
        <GroupReservationProtocolWrapper
          closeDrawer={handleCloseProtocol}
          reservation={openedProtocol}
          pickUp={pickUpProtocol}
        />
      </BaseDrawer>
    </>
  )
}

export default GroupReservationList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
