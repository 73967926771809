import gql from 'graphql-tag'
import { IOrigoCard } from './queryOrigoCard'

export interface IOrigoAccount {
  id: string
  status: string
  userId: string
  origoCards: IOrigoCard[]
  user: {
    login: string
    tenant: {
      name: string
    }
  }
}

export interface IQueryOrigoAccountData {
  queryOrigoAccount: IOrigoAccount[]
}

export const QUERY_ORIGO_ACCOUNT = gql`
  query queryOrigoAccount {
    queryOrigoAccount {
      id
      status
      userId
      origoCards: origo_cards {
        cardNumber
        status
        deviceDescription
        accessGranted
        privilegeRequested
        privilegeGranted
      }
      user {
        login
        tenant {
          name
        }
      }
    }
  }
`
