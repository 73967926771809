import gql from 'graphql-tag'
import { DietType } from '../shared/sharedTypes'

export type WeekDayAvailability = {
  week_day: string
}

export type DateAvailability = {
  date: string
}

export interface IQueryRestaurantMenuItemsData {
  queryRestaurant: {
    id: string
    name: string
    visible: boolean
    menuItems: {
      id: string
      namePl: string
      nameEn: string
      amount: number
      descriptionPl: string
      descriptionEn: string
      dietType: DietType
      price: number
      visible: boolean
      isAvailable: boolean
      category: {
        id: string
        titlePl: string
        titleEn: string
      }
      unit: {
        name: string
      }
      availability: (WeekDayAvailability | DateAvailability)[]
    }[]
  }[]
}

export const QUERY_RESTAURANT_MENU_ITEMS = gql`
  query queryRestaurant($filter: RestaurantFilter, $order: RestaurantOrder) {
    queryRestaurant(filter: $filter, order: $order) {
      id
      name
      visible
      menuItems: menu_items {
        id
        namePl: name
        nameEn: name_en
        descriptionPl: description
        descriptionEn: description_en
        price
        amount
        visible
        isAvailable: is_available
        category {
          id
          titlePl: title
          titleEn: title_en
        }
        dietType: diet_type
        unit {
          name
        }
        availability {
          ... on WeekDayAvailability {
            week_day
            id
          }
          ... on DateAvailability {
            date
            id
          }
        }
      }
    }
  }
`
