import { useState } from 'react'
import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import DateRangeFilter from '../Table/DateRangeFilter'
import { date2localeString } from '../../utils/formatDate'

const DateRangeParkingOccupancyFilter = ({
  rangeFrom,
  rangeTo,
  setRangeFrom,
  setRangeTo,
}: {
  rangeFrom: Date | null
  setRangeFrom: (value: Date | null) => void
  rangeTo: Date | null
  setRangeTo: (value: Date | null) => void
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const lang = i18n.language
  const [fromDate, setFromDate] = useState<Date | null>(rangeFrom)
  const [toDate, setToDate] = useState<Date | null>(rangeTo)

  const formatDate = (date: Date) =>
    date2localeString(date, lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

  const handleClearFilters = () => {
    setFromDate(null)
    setToDate(null)
    setRangeFrom(null)
    setRangeTo(null)
  }

  const handleDeleteRangeFrom = () => {
    setFromDate(null)
    setRangeFrom(null)
  }
  const handleDeleteRangeTo = () => {
    setToDate(null)
    setRangeTo(null)
  }

  const onSubmitRange = () => {
    setRangeFrom(fromDate)
    toDate && setRangeTo(toDate)
  }

  return (
    <>
      <GridStyled container spacing={2}>
        <Grid item>
          <DateRangeFilter
            rangeFrom={fromDate}
            rangeTo={toDate}
            setRangeFrom={setFromDate}
            setRangeTo={setToDate}
          />
        </Grid>
        <ButtonWrapper>
          {fromDate && (
            <ButtonStyled variant="text" onClick={onSubmitRange}>
              {t('btn:submit_range')}
            </ButtonStyled>
          )}
        </ButtonWrapper>
      </GridStyled>
      <Stack direction="row" spacing={1}>
        {rangeFrom && (
          <ChipStyled
            label={`${t('from')} ${formatDate(new Date(rangeFrom))}`}
            onDelete={handleDeleteRangeFrom}
          />
        )}
        {rangeTo && (
          <ChipStyled
            label={`${t('to')} ${formatDate(new Date(rangeTo))}`}
            onDelete={handleDeleteRangeTo}
          />
        )}
        {(rangeFrom || rangeTo) && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </>
  )
}

export default DateRangeParkingOccupancyFilter

const GridStyled = styled(Grid)`
  padding: 16px 0;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
const ButtonWrapper = styled(Grid)`
  padding: 24px 0 0 16px;
`
