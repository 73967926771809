import React from 'react'
import styled from 'styled-components'
import { BaseButton, BaseButtonOutlined } from '../Shared/BaseButton'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DrawerButtons = ({
  handleAccept,
  handleCancel,
  acceptText,
}: {
  handleAccept: () => void
  handleCancel: () => void
  acceptText?: string
}) => {
  const { t } = useTranslation(['btn'])
  return (
    <ButtonsWrapper container justifyContent={'center'}>
      <Col item xs={6}>
        <BaseButtonOutlined onClick={handleCancel} data-e2e="cancel">
          {t('cancel')}
        </BaseButtonOutlined>
      </Col>
      <Col item xs={6}>
        <BaseButton onClick={handleAccept} data-e2e="submit">
          {acceptText ? acceptText : t('save')}
        </BaseButton>
      </Col>
    </ButtonsWrapper>
  )
}

export default DrawerButtons

const Col = styled(Grid)`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const ButtonsWrapper = styled(Grid)`
  padding-top: 30px;
`
