import React, { useState } from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ITableRow } from './QuickPromoTable'
import QuickPromoImage from './QuickPromoImage'
import ExtrasTypeRadio, { ExtrasType } from './ExtrasTypeRadio'
import EventSelect from './EventSelect'
import QuickPromoSlotPickers from './QuickPromoSlotPickers'
import { TextBody1, TextBody2 } from '../Styles/TextCustom'
import { Checkbox, FormControlLabel } from '@mui/material'
import { themeColors } from '../../const/colors'

const QuickPromoInputs = ({
  control,
  errors,
  data,
  watch,
  setValue,
  extrasType,
  setExtrasType,
}: {
  control: any
  errors: any
  data?: ITableRow
  watch: any
  setValue: any
  extrasType: ExtrasType
  setExtrasType: (v: ExtrasType) => void
}) => {
  const { t } = useTranslation(['promo'])
  const [customUrl, setCustomUrl] = useState(false)

  return (
    <Container>
      <ExtrasTypeRadio type={extrasType} setType={setExtrasType} />
      <Input
        label={t('company_name')}
        name="companyName"
        control={control}
        errors={errors}
        defaultValue={data?.companyName || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="company-name"
      />
      <TextBody1>{t('choose_promotion_logo')}</TextBody1>
      {data?.companyLogo && (
        <Wrapper>
          <TextBody2 color={themeColors.gray}>{t('chosen_url')}</TextBody2>
          <TextBody2 color={themeColors.gray}>{data.companyLogo}</TextBody2>
        </Wrapper>
      )}
      <FormControlLabelStyled
        control={
          <Checkbox
            checked={customUrl}
            onChange={() => setCustomUrl(!customUrl)}
            color="primary"
            data-e2e="custom-url"
          />
        }
        label={t('custom_url')}
      />
      {!customUrl && (
        <QuickPromoImage
          control={control}
          imageUrl={data?.companyLogo}
          watch={watch}
          setValue={setValue}
        />
      )}
      {customUrl && (
        <Input
          label={t('image_url')}
          name="companyLogo2"
          control={control}
          errors={errors}
          defaultValue={data?.companyLogo || ''}
          data-e2e="custom-company-name"
        />
      )}
      <Input
        label={t('title')}
        name="title"
        control={control}
        errors={errors}
        defaultValue={data?.title || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="title"
      />
      <Input
        label={t('title_en')}
        name="titleEn"
        control={control}
        errors={errors}
        defaultValue={data?.titleEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="title-en"
      />
      <Input
        label={t('discount')}
        name="discount"
        control={control}
        errors={errors}
        defaultValue={data?.discount || ''}
        data-e2e="discount"
      />
      <Input
        label={t('description')}
        name="descriptionPl"
        control={control}
        errors={errors}
        defaultValue={data?.description || ''}
        rules={{ required: `${t('form:field_required')}` }}
        multiline
        rows={4}
        data-e2e="description"
      />
      <Input
        label={t('description_en')}
        name="description"
        control={control}
        errors={errors}
        defaultValue={data?.descriptionEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        multiline
        rows={4}
        data-e2e="description-en"
      />
      <Input
        label={t('location')}
        name="locationPl"
        control={control}
        errors={errors}
        defaultValue={data?.location || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="location"
      />
      <Input
        label={t('location_en')}
        name="location"
        control={control}
        errors={errors}
        defaultValue={data?.locationEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="location-en"
      />
      {extrasType !== 'event' && (
        <>
          <Input
            label={t('instruction')}
            name="instructionPl"
            control={control}
            errors={errors}
            defaultValue={
              data?.instruction ||
              'Aby skorzystać z promocji przy zakupie pokaż aplikację sprzedawcy.'
            }
            multiline
            rows={3}
            data-e2e="instruction"
          />
          <Input
            label={t('instruction_en')}
            name="instruction"
            control={control}
            errors={errors}
            defaultValue={
              data?.instructionEn ||
              'To benefit from the promotion during the purchase, show the seller the application.'
            }
            multiline
            rows={3}
            data-e2e="instruction-en"
          />
        </>
      )}

      {extrasType === 'event' && (
        <EventSelect
          control={control}
          eventId={data?.eventId}
          setValue={setValue}
        />
      )}
      <QuickPromoSlotPickers
        control={control}
        errors={errors}
        defaultFrom={data?.validFrom ? new Date(data.validFrom) : null}
        defaultTo={data?.validTo ? new Date(data.validTo) : null}
        watch={watch}
      />
    </Container>
  )
}

export default QuickPromoInputs
const Container = styled.div`
  padding: 24px 0 10px;
`
const FormControlLabelStyled = styled(FormControlLabel)`
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  padding: 0 0 16px 6px;
`
