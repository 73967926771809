import { useState } from 'react'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import { TextBody2Medium, TextBody2Bold, TextBody1 } from '../Styles/TextCustom'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  RESOLVE_ISSUE,
  IResolveIssueVars,
  IResolveIssueData,
} from '../../graphql/userIssues/resolveIssue'
import { useForm } from 'react-hook-form'
import { ApolloError, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Text } from '../Styles/DetailsDrawersStyles'
import { dateIso2localeString } from '../../utils/formatDate'
import DrawerHeader from '../Shared/DrawerHeader'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Input from '../Shared/Input'
import { ITableRow } from './MobileAccessCardIssuesTable'
import { IssueStatus } from '../../graphql/userIssues/queryIssue'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  comment: string
}

const IssueDetails = ({
  issue,
  closeIssue,
  updateList,
  updateAggregatedCount,
  tab,
}: {
  issue?: ITableRow
  closeIssue: () => void
  updateList: () => void
  updateAggregatedCount: () => void
  tab: number
}) => {
  const { t, i18n } = useTranslation(['skd'])
  const [errorMessage, setErrorMessage] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>()

  const onCompleted = (data: IResolveIssueData) => {
    if (data?.resolveIssue.status) {
      updateList()
      updateAggregatedCount()
      closeIssue()
    } else setErrorMessage(`${t(`code:${data.resolveIssue.code}`)}`)
  }

  const [resolveIssue, { loading }] = useMutation<
    IResolveIssueData,
    IResolveIssueVars
  >(RESOLVE_ISSUE, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (loading || !issue) {
    return <FullScreenLoader />
  }

  const {
    id,
    fullName,
    login,
    email,
    createdAt,
    closeAt,
    resolvedBy,
    message,
    reply,
    status,
  } = issue

  const issueData = [
    { label: t('tenant_request_details:full_name'), value: fullName },
    { label: t('tenant_request_details:login'), value: login },
    { label: t('tenant_request_details:contact_email'), value: email },
    {
      label: t('date'),
      value: createdAt && dateIso2localeString(createdAt, i18n.language),
    },
    {
      label: t('tenant_request_details:close_at'),
      value: tab && closeAt && dateIso2localeString(closeAt, i18n.language),
    },
    {
      label: t('tenant_request_details:resolved_by'),
      value: tab && `${resolvedBy?.firstName} ${resolvedBy?.lastName}`,
    },
  ]

  const handleAccept = (formData: FormData) => {
    const { comment } = formData
    console.log(comment)
    resolveIssue({
      variables: {
        id,
        comment,
        status: IssueStatus.resolved,
      },
    })
  }

  const handleReject = (formData: FormData) => {
    const { comment } = formData
    if (!!comment) {
      resolveIssue({
        variables: {
          id,
          comment,
          status: IssueStatus.rejected,
        },
      })
    } else
      setError(
        'comment',
        { message: t('form:field_required') },
        { shouldFocus: true }
      )
  }

  return (
    <>
      <DrawerHeader
        title={t('access_confirmation_request')}
        handleClose={closeIssue}
      />

      <DetailsWrapper>
        {issueData.map(
          (item) =>
            !!item.value && (
              <Row key={item.label}>
                <DetailText>{`${item.label} `}</DetailText>
                <DetailBold>{item.value}</DetailBold>
              </Row>
            )
        )}
      </DetailsWrapper>
      <DetailsWrapper>
        <TextTitle>{t('message')}</TextTitle>
        <Text>{message}</Text>
        {!!tab && (
          <>
            <TextTitle
              color={
                status === IssueStatus.accepted
                  ? themeColors.accept
                  : themeColors.negative
              }
            >
              {t(status)}
            </TextTitle>
            {reply && (
              <Grid>
                <TextTitle>{t('tenant_request_details:reply')}</TextTitle>
                <Text>{reply}</Text>
              </Grid>
            )}
          </>
        )}
        {!tab && (
          <>
            <Input
              label={t('comment')}
              name={'comment'}
              control={control}
              errors={errors}
              multiline
              rows={3}
            />
            <Row container justifyContent={'flex-end'}>
              <Col item xs={6}>
                <BaseButton
                  icon={faTimes as IconProp}
                  onClick={handleSubmit(handleReject)}
                  customColor={themeColors.negative}
                >
                  {t('btn:reject')}
                </BaseButton>
              </Col>
              <Col item xs={6}>
                <BaseButton
                  icon={faCheck as IconProp}
                  onClick={handleSubmit(handleAccept)}
                  customColor={themeColors.accept}
                >
                  {t('btn:confirm')}
                </BaseButton>
              </Col>
            </Row>
          </>
        )}
      </DetailsWrapper>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </>
  )
}

export default IssueDetails

const Col = styled(Grid)`
  padding: 8px 16px;
`
const TextTitle = styled(TextBody1)`
  font-weight: 600;
`
const Row = styled(Grid)`
  padding: 4px 6px;
`
const DetailText = styled(TextBody2Medium)`
  display: inline;
`
const DetailBold = styled(TextBody2Bold)`
  display: inline;
`
