import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useQuery } from '@apollo/client'
import {
  QUERY_LOCKER_ORDERS,
  IQueryLockerOrdersData,
} from '../../graphql/lockers/queryLockerOrders'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import { PaymentStatus, ILog } from '../../graphql/shared/sharedTypes'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import LockerPaymentDetails from '../../components/Lockers/LockerPaymentDetails'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { IPassProps } from '../../components/UserDetails/UserSearch'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import { useParams } from 'react-router-dom'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'createdAt'
    | 'createdAtCSV'
    | 'validFrom'
    | 'validFromCSV'
    | 'validTo'
    | 'validToCSV'
    | 'productName'
    | 'status'
    | 'building'
    | 'fv'
    | 'fvCSV'
    | 'fullName'
    | 'login'
    | 'nip'
    | 'buyerName'
    | 'zipcode'
    | 'street'
    | 'city'
    | 'country'
    | 'tenantName'
    | 'price'
    | 'priceCSV'
    | 'sandbox'
}

export interface ITableRow {
  id: string
  paymentId: string
  sessionId: string
  createdAt: string
  createdAtCSV: string
  validFrom: string
  validFromCSV: string
  validTo: string
  validToCSV?: string
  productName: string
  building?: string
  fv: boolean
  fvCSV: string
  invoiceEmail: string
  login: string
  fullName: string
  nip?: string
  buyerName?: string
  zipcode?: string
  street?: string
  city?: string
  country?: string
  tenantName?: string
  status: PaymentStatus
  price?: string
  priceCSV?: string
  logs: ILog[]
  sandbox?: string
  seller?: {
    name: string
    nip: string
  }
}
interface stateType {
  columnFilter?: IPassProps
  prop: {
    paymentId?: string
  }
}

const PaymentHistory = () => {
  const { t, i18n } = useTranslation(['payment_history'])
  const location = useLocation()
  const state: stateType = location.state as stateType
  const { usr } = useParams()
  const paymentId = state?.prop?.paymentId
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [error, setError] = useState('')

  const csvHeaders = [
    {
      label: t('payment_id'),
      key: 'id',
    },
    {
      label: t('payment_date'),
      key: 'createdAtCSV',
    },
    {
      label: t('lease_from'),
      key: 'validFromCSV',
    },
    {
      label: t('lease_to'),
      key: 'validToCSV',
    },
    {
      label: t('product_name'),
      key: 'productName',
    },
    {
      label: t('building'),
      key: 'building',
    },
    {
      label: 'FV',
      key: 'fvCSV',
    },
    {
      label: 'NIP',
      key: 'nip',
    },
    {
      label: t('columns:full_name'),
      key: 'fullName',
    },
    {
      label: t('zipcode'),
      key: 'zipcode',
    },
    {
      label: t('street'),
      key: 'street',
    },
    {
      label: t('city'),
      key: 'city',
    },
    {
      label: t('country'),
      key: 'country',
    },
    {
      label: t('locker_number'),
      key: 'lockerNumber',
    },
    {
      label: t('tenant_name'),
      key: 'tenantName',
    },
    {
      label: `${t('payment_value_gross')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: 'Status',
      key: 'status',
    },
  ]

  const { data, refetch, loading } = useQuery<IQueryLockerOrdersData>(
    QUERY_LOCKER_ORDERS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => {
        setError(error.message)
      },
    }
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      { Header: t('payment_id'), accessor: 'id', isVisible: false },
      {
        Header: t('payment_date'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('payment_date_csv'),
        accessor: 'createdAtCSV',
        isVisible: false,
      },

      {
        Header: t('lease_from'),
        accessor: 'validFrom',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('lease_from'),
        accessor: 'validFromCSV',
        isVisible: false,
      },
      {
        Header: t('lease_to'),
        accessor: 'validTo',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('lease_to'),
        accessor: 'validToCSV',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('product_name'),
        accessor: 'productName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('payment_status'),
        accessor: 'status',
        Cell: (props: { value: string }) => t(`payment_status:${props.value}`),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('building'),
        accessor: 'building',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('fv'),
        accessor: 'fv',
        Cell: (props: { value: string }): string =>
          props.value ? t('bool:yes') : t('bool:no'),
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('fv'),
        accessor: 'fvCSV',
        isVisible: false,
      },
      {
        Header: t('columns:full_name'),
        accessor: 'fullName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('columns:login'),
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('nip'),
        accessor: 'nip',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('name'),
        accessor: 'buyerName',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('zipcode'),
        accessor: 'zipcode',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('street'),
        accessor: 'street',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('city'),
        accessor: 'city',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('country'),
        accessor: 'country',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('tenant_name'),
        accessor: 'tenantName',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('payment_value_gross'),
        accessor: 'price',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('payment_value_gross'),
        accessor: 'priceCSV',
        isVisible: false,
      },
      {
        Header: 'Sandbox',
        accessor: 'sandbox',
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.orders?.map(
        ({
          id,
          product: { name, nameEn, seller },
          locker: { type, building },
          validFrom,
          validTo,
          user: { login, firstName, lastName, tenant },
          invoiceNeeded,
          buyer,
          payment: {
            createdAt,
            paymentId,
            status,
            logs,
            sandbox,
            sessionId,
            invoiceEmail,
            amount,
          },
        }) => ({
          id,
          paymentId,
          sessionId,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          validFrom,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validTo,
          validToCSV: dateIso2ExcelFormat(validTo),
          productName: name,
          building: building.name,
          fv: invoiceNeeded,
          fvCSV: invoiceNeeded ? t('bool:yes') : t('bool:no'),
          login,
          fullName: `${firstName} ${lastName}`,
          nip: buyer?.nip,
          buyerName: buyer?.buyerName,
          zipcode: buyer?.zipcode,
          street: buyer?.street,
          city: buyer?.city,
          country: buyer?.country,
          tenantName: tenant?.tenantName,
          status,
          price: formatPrice(amount),
          priceCSV: formatToExcelPrice(amount),
          sandbox: sandbox ? 'sandbox' : undefined,
          logs,
          seller,
          invoiceEmail,
        })
      ) || []
    )
  }, [data, t])

  useEffect(() => {
    if (paymentId) {
      setOpenedDetails(tableData?.find(({ id }) => id === paymentId))
    }
  }, [paymentId, tableData])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('locker_payment_history')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        orderByProps="createdAt"
        csvExport
        csvHeaders={csvHeaders}
        loginFilter={usr}
        onRowClick={(rowData: ITableRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getPaymentStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <LockerPaymentDetails
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default PaymentHistory

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
