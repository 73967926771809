import gql from 'graphql-tag'

export enum VehicleType {
  car = 'Car',
  motorcycle = 'Motorcycle',
  bike = 'Bike',
}

export interface IParkingPassesData {
  queryParkingPass: {
    minAdvanceDays: number
    maxAdvanceDays: number
    disabled: boolean
    durationDays: number
    id: string
    nameEn: string
    namePl: string
    overflowReservationDays: number
    parking: {
      name: string
      id: string
      capacity: any
      seller: {
        name: string
      }
    }
    seller?: {
      name: string
    }
    price: number
    regulation: {
      title: string
      regulationId: string
    }
    sort: number
    amanoProductNo: number
    amanoCurrency: string
    amanoSellCard: boolean
    minAdvanceWorkDays: number
    vehicleType: VehicleType
  }[]
}

export const GET_PARKING_PASSES = gql`
  query parkingPass {
    queryParkingPass {
      minAdvanceDays: min_advance_days
      maxAdvanceDays: max_advance_days
      disabled
      durationDays: duration_days
      id
      nameEn: name_en
      namePl: name
      overflowReservationDays: overflow_reservation_days
      parking {
        name
        id
        capacity
        seller {
          name
        }
      }
      seller {
        name
      }
      price
      regulation {
        title
        regulationId: id
      }
      sort
      amanoProductNo: amano_productno
      amanoCurrency: amano_currency
      amanoSellCard: amano_sell_card
      minAdvanceWorkDays: min_advance_work_days
      vehicleType: vehicle_type
    }
  }
`
