import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import DatePickerController from '../Shared/DatePickerController'
import { IFormError } from '../../types/FormTypes'
import { InputMessage } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'

const LeaseTermPickers = ({
  errors,
  control,
  defaultFrom = null,
  defaultTo = null,
}: {
  control: any
  errors: IFormError | any
  defaultFrom?: Date | null
  defaultTo?: Date | null
}) => {
  const { t } = useTranslation(['lockers'])

  return (
    <Grid container spacing={1}>
      <Grid xs={6} item>
        <div data-e2e="start-date-picker">
          <DatePickerController
            name={'validFrom'}
            label={t('from')}
            control={control}
            errors={errors}
            defaultValue={defaultFrom}
            rules={{
              required: `${t('form:field_required')}`,
              validate: (val: Date) =>
                defaultFrom && defaultTo
                  ? val >= defaultFrom && val < defaultTo
                  : true,
            }}
          />
        </div>
        {errors.validFrom && errors?.validFrom.type === 'validate' && (
          <InputMessage color={themeColors.error}>
            {t('valid_valid_from')}
          </InputMessage>
        )}
      </Grid>
      <Grid xs={6} item>
        <div data-e2e="end-date-picker">
          <DatePickerController
            name={'validTo'}
            label={t('to')}
            control={control}
            errors={errors}
            defaultValue={defaultTo}
            rules={{
              validate: (val: Date) =>
                defaultFrom && defaultTo
                  ? val > defaultFrom && val <= defaultTo
                  : true,
            }}
          />
        </div>
        {errors.validTo && errors?.validTo.type === 'validate' && (
          <InputMessage color={themeColors.error}>
            {t('valid_valid_to')}
          </InputMessage>
        )}
      </Grid>
    </Grid>
  )
}

export default LeaseTermPickers
