import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  fromPromise,
} from '@apollo/client'
import getEnv from '../env'
import jwtDecode from 'jwt-decode'
import { REFRESH_TOKEN } from '../../graphql/auth/refreshToken'
interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const isTokenValid = (token?: string) => {
  if (!token) {
    return false
  }
  const { exp }: any = jwtDecode(token)
  const now = Date.now()
  return now < exp * 1000
}

const getNewToken = () => {
  const refreshToken = localStorage.getItem('refreshToken')
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    uri: getEnv('API_URL'),
  })
  return apolloClient.query({
    query: REFRESH_TOKEN,
    variables: {
      refreshToken,
      app: `${window.TAG || 'local'}`,
      apiVersion: 36,
    },
  })
}

export const refreshTokenLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('accessToken')

  if (token && !isTokenValid(token)) {
    // console.log('odświeżam token')
    return fromPromise(
      getNewToken()
        .then(({ data: { refresh } }) => {
          if (refresh.status) {
            localStorage.setItem('accessToken', refresh.accessToken)
            localStorage.setItem('refreshToken', refresh.refreshToken)
          } else {
            console.log('REFRESH TOKEN ERROR:', refresh.error)
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accessToken')
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log('REFRESH TOKEN NETWORK ERROR', error)
          localStorage.clear()
          window.location.reload()
        })
    ).flatMap(() => {
      // console.log('Retry query:', operation.operationName);
      return forward(operation)
    })
  }
  return forward(operation)
})
