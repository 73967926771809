import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RTTable from '../../components/RTTable'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  GET_TENANTS_WITH_DOMAINS,
  IGetTenantsWithDomainsData,
  IParkingPool,
} from '../../graphql/tenant/queryTenantWithDomains'
import { BaseButton } from '../../components/Shared/BaseButton'
import BaseModal from '../../components/Shared/BaseModal'
import AddTenantForm from '../../components/Tenant/AddTenantForm'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import TenantDetails from '../../components/Tenant/TenantDetails'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { ILog } from '../../graphql/shared/sharedTypes'
import { ISeos, IHIDConfig } from '../../graphql/tenant/queryTenantWithDomains'

interface ITableColumns {
  Header: string
  accessor: 'name' | 'domains' | 'createdAt' | 'createdAt_csv' | 'parkingPool'
}

export interface ITableRow {
  name: string
  slug: string
  domains: string
  domainsArray: string[]
  createdAt: string
  createdAt_csv: string
  id: string
  parkingPool: string
  parkingPoolData?: IParkingPool
  logs: ILog[]
  seos?: ISeos
  hidConfig?: IHIDConfig
}

const TenantsList = () => {
  const { t, i18n } = useTranslation(['columns', 'admin_menu', 'btn', 'tenant'])
  const [open, setOpen] = useState(false)
  const [tenantToEdit, setTenantToEdit] = useState<ITableRow>()
  const { data, loading, refetch } = useQuery<IGetTenantsWithDomainsData>(
    GET_TENANTS_WITH_DOMAINS,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  )

  const csvHeaders = [
    { label: t('tenant'), key: 'name' },
    { label: t('domains'), key: 'domains' },
    { label: t('creation_date'), key: 'createdAt_csv' },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('tenant'),
        accessor: 'name',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('domains'),
        accessor: 'domains',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('creation_date'),
        accessor: 'createdAt',
        filter: 'filterRowsWithSubrows',
        Cell: (props: { value: string }) =>
          !!props.value && dateIso2localeString(props.value, i18n.language),
      },
      {
        Header: 'createdAt_csv',
        accessor: 'createdAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_pool'),
        accessor: 'parkingPool',
        isVisible: true,
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t, i18n]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data?.queryTenant.map(
        ({
          id,
          name,
          slug,
          domains,
          createdAt,
          parkingPool,
          logs,
          seos,
          hidConfig,
        }) => ({
          id,
          name,
          slug,
          domainsArray: domains.map(({ domain }) => domain),
          domains: domains.map(({ domain }) => domain).join(', '),
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          parkingPool: parkingPool ? String(!!parkingPool) : '',
          parkingPoolData: parkingPool,
          logs: logs,
          domainsData: domains,
          seos,
          hidConfig,
        })
      ) || [],
    [data?.queryTenant]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">
          {t('admin_menu:companies_list')}
        </TypographyStyled>
        <ShortButton onClick={() => setOpen(true)} data-e2e="add-tenant">
          {t('btn:add_tenant')}
        </ShortButton>
      </HeaderContainer>
      <RTTable
        columns={tableColumns}
        data={tableData}
        onRowClick={(rowData) => setTenantToEdit(rowData)}
        pagination
        csvExport
        rowSelect
        orderByProps="createdAt"
        csvHeaders={csvHeaders}
      />
      <BaseDrawer open={!!tenantToEdit} variant={'temporary'}>
        {tenantToEdit && (
          <TenantDetails
            handleClose={() => setTenantToEdit(undefined)}
            handleUpdateTenants={refetch}
            tenantToEdit={tenantToEdit}
          />
        )}
      </BaseDrawer>
      <BaseModal title={t('tenant:add_new_tenant')} open={open} actions={false}>
        <AddTenantForm
          handleClose={() => setOpen(false)}
          handleUpdateTenants={refetch}
        />
      </BaseModal>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
const ShortButton = styled(BaseButton)`
  max-width: 185px;
`

export default TenantsList
