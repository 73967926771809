import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ClickAwayListener } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  UPDATE_QUICK_PROMO,
  IUpdateQuickPromoData,
  IUpdateQuickPromoVars,
} from '../../graphql/quickPromo/updateQuickPromo'
import { ITableRow } from './QuickPromoTable'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'
import QuickPromoInputs from './QuickPromoInputs'
import { date2isoString } from '../../utils/formatDate'
import { ExtrasType } from './ExtrasTypeRadio'

type FormData = {
  companyName: string
  companyLogo: {
    value: string
    label: string
  }
  companyLogo2: string
  title: string
  titleEn: string
  discount?: string
  descriptionPl: string
  description: string
  instructionPl: string
  instruction: string
  locationPl: string
  location: string
  dateFrom?: Date
  dateTo?: Date
  timeFrom?: Date
  timeTo?: Date
  eventId?: {
    value: string
    label: string
  }
  hasDiscount: boolean
}

const QuickPromoDetails = ({
  data,
  refetch,
  closeDetails,
}: {
  data: ITableRow
  refetch: () => void
  closeDetails: () => void
}) => {
  const { t } = useTranslation(['promo'])
  const [extrasType, setExtrasType] = useState<ExtrasType>('fixed')
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)
  const createDate = (date?: any, time?: any) => {
    let createdDate = date ? new Date(date) : undefined
    let hours = new Date(time).getHours()
    let minutes = new Date(time).getMinutes()
    if (date && time) {
      createdDate?.setHours(hours)
      createdDate?.setMinutes(minutes)
    } else if (!time) {
      createdDate?.setHours(0)
      createdDate?.setMinutes(0)
    }
    return createdDate ? date2isoString(createdDate) : undefined
  }
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    watch,
    setValue,
  } = useForm<FormData>()

  const onSubmit = (formData: FormData) => {
    const {
      companyName,
      companyLogo,
      companyLogo2,
      title,
      titleEn,
      discount,
      description,
      descriptionPl,
      instruction,
      instructionPl,
      location,
      locationPl,
      eventId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
    } = formData

    const validFrom = createDate(dateFrom, timeFrom)
    const validTo = createDate(dateTo, timeTo)

    const remove =
      !validFrom && !validTo
        ? { valid_from: data.validFrom, valid_to: data.validTo }
        : !validFrom
        ? { valid_from: data.validFrom }
        : !validTo
        ? { valid_to: data.validTo }
        : {}

    const logo = companyLogo2
      ? companyLogo2
      : companyLogo
      ? companyLogo.value
      : ''

    updateQuickPromo({
      variables: {
        id: data.id,
        companyName,
        companyLogo: logo,
        title,
        titleEn,
        description,
        descriptionPl,
        instruction,
        instructionPl,
        location,
        locationPl,
        eventId:
          extrasType === 'event' && eventId ? eventId.value.toString() : '',
        hasDiscount: extrasType === 'fixed',
        validFrom,
        validTo,
        discount,
        remove,
      },
    })
  }

  const updateAndClose = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const [updateQuickPromo] = useMutation<
    IUpdateQuickPromoData,
    IUpdateQuickPromoVars
  >(UPDATE_QUICK_PROMO, {
    onCompleted: updateAndClose,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!!data.eventId) {
      setExtrasType('event')
    } else if (!data.hasDiscount) {
      setExtrasType('temporary')
    } else setExtrasType('fixed')
  }, [data])

  const handleClickAway = () =>
    isDirty && !!Object.keys(dirtyFields).length
      ? setOpenClickAwayModal(true)
      : closeDetails()

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('edit_promo')} handleClose={handleClickAway} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <QuickPromoInputs
              control={control}
              errors={errors}
              data={data}
              watch={watch}
              setValue={setValue}
              extrasType={extrasType}
              setExtrasType={setExtrasType}
            />
            <Buttons
              handleAccept={handleSubmit(onSubmit)}
              handleCancel={handleClickAway}
            />
          </form>
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default QuickPromoDetails

const Buttons = styled(DrawerButtons)`
  margin: 16px;
`
