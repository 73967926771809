import { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  IQueryRestaurantMenuItemsData,
  QUERY_RESTAURANT_MENU_ITEMS,
} from '../../graphql/gastro/queryRestaurantMenuItems'
import { DELETE_MENU_ITEM } from '../../graphql/gastro/deleteMenuItem'
import { SET_MENU_ITEM_AVAILABILITY } from '../../graphql/gastro/setMenuItemAvailability'
import { useTranslation } from 'react-i18next'
import RTTable from '../../components/RTTable'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { IconButton, Tooltip, Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import EditMenuItem from './EditMenuItem'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import styled from 'styled-components'
import AddMenuItem from './AddMenuItem'
import BaseModal from '../Shared/BaseModal'
import { toast } from 'react-toastify'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { dateIso2localeString } from '../../utils/formatDate'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { TextBody1 } from '../Styles/TextCustom'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
interface ITableColumns {
  Header: string
  accessor:
    | 'name'
    | 'price'
    | 'priceCSV'
    | 'availability'
    | 'isAvailable'
    | 'dietType'
    | 'category'
}
interface ITableRow {
  id: string
  name: string
  price: string
  priceCSV: string
  availability: string
  isAvailable: boolean
  dietType: string
  category: string
}

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const MenuTable: FC<{
  restaurant: any
  openedAddForm: boolean
  closeAddForm: () => void
}> = ({ restaurant, openedAddForm, closeAddForm }) => {
  const [available, setAvailable] = useState<string[]>([])
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [itemToDelete, setItemToDelete] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const { t, i18n } = useTranslation([
    'columns',
    'diet_types',
    'categories',
    'menu_item_form',
    'gastro',
  ])
  const lang = i18n.language

  const csvHeaders = [
    {
      label: t('dish_name'),
      key: 'name',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: t('availability'),
      key: 'isAvailable',
    },
    {
      label: t('diet_type'),
      key: 'dietType',
    },
    {
      label: t('category'),
      key: 'category',
    },
  ]

  const onDeleteCompleted = () => {
    refetch()
    successNotify(`${t('menu_item_form:dish_deleted')}`)
  }

  const { data, refetch, loading } = useQuery<IQueryRestaurantMenuItemsData>(
    QUERY_RESTAURANT_MENU_ITEMS,
    {
      variables: {
        filter: { id: [restaurant] },
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [updateMenuItem] = useMutation(SET_MENU_ITEM_AVAILABILITY, {
    onCompleted: () => refetch(),
  })

  const [deleteMenuItem] = useMutation(DELETE_MENU_ITEM, {
    onCompleted: onDeleteCompleted,
  })

  const handleDeleteMenuItem = (id: string) => {
    deleteMenuItem({
      variables: {
        id: [id],
      },
    })
    setItemToDelete('')
  }

  const handleAvailabilityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = event.target
      checked
        ? setAvailable((items) => items.concat([name]))
        : setAvailable((items) => items.filter((item) => item !== name))
      updateMenuItem({
        variables: {
          input: { filter: { id: name }, set: { visible: checked } },
        },
      })
    },
    [updateMenuItem]
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('dish_name'),
        accessor: 'name',
        filter: 'filterRowsWithSubrows',
        Cell: (cellInfo: any) => (
          <NameCell onClick={() => setOpenedDetails(cellInfo.row.original)}>
            <ItemNameText>{cellInfo.row.original.name}</ItemNameText>
            <ItemAmountText>{`${cellInfo.row.original.amount} ${cellInfo.row.original.unit}`}</ItemAmountText>
          </NameCell>
        ),
      },
      {
        Header: t('price'),
        accessor: 'price',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('price'),
        accessor: 'priceCSV',
        isVisible: false,
      },
      {
        Header: t('visible'),
        filter: 'filterRowsWithSubrows',
        accessor: 'availability',
        Cell: (cellInfo: any) => (
          <Grid>
            <AvailabilitySwitch
              checked={available.includes(cellInfo.row.original.id)}
              name={cellInfo.row.original.id}
              onChange={handleAvailabilityChange}
            />
          </Grid>
        ),
      },
      {
        Header: t('availability'),
        filter: 'filterRowsWithSubrows',
        accessor: 'isAvailable',
        Cell: (cellInfo: any) => (
          <Grid>
            {cellInfo.row.original.availabilityDate ? (
              <AvailabilityContainer container direction="row">
                <Tooltip title={`${t('gastro:available_tip')}`}>
                  <CalendarIcon
                    fontSize="small"
                    color={
                      cellInfo.row.original.visible &&
                      cellInfo.row.original.isAvailable
                        ? 'inherit'
                        : 'error'
                    }
                  />
                </Tooltip>
                <ItemAmountText
                  data-e2e="availability-date"
                  color={
                    cellInfo.row.original.visible &&
                    cellInfo.row.original.isAvailable
                      ? themeColors.success
                      : themeColors.error
                  }
                >
                  {cellInfo.row.original.availabilityDate}
                </ItemAmountText>
              </AvailabilityContainer>
            ) : (
              <ItemNameText
                color={
                  cellInfo.row.original.visible
                    ? themeColors.success
                    : themeColors.error
                }
              >
                {cellInfo.row.original.visible
                  ? t('generic:yes')
                  : t('generic:no')}
              </ItemNameText>
            )}
          </Grid>
        ),
      },
      {
        Header: t('diet_type'),
        accessor: 'dietType',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('category'),
        accessor: 'category',
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t, handleAvailabilityChange, available]
  )

  const daysForSort: any = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  }
  const tableData: ITableRow[] = useMemo(
    () =>
      data?.queryRestaurant[0]?.menuItems.map(
        ({
          id,
          namePl,
          nameEn,
          descriptionPl,
          descriptionEn,
          price,
          visible,
          dietType,
          category,
          amount,
          unit,
          availability,
          isAvailable,
        }) => {
          const availabilityDataDisplay = (data: any) => {
            const date = data.find(
              (item: any) => Object.keys(item)[0] === 'date'
            )
            if (date) {
              return dateIso2localeString(date.date, i18n.language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            } else {
              const weekdays = data
                .filter((item: any) => Object.keys(item)[0] === 'week_day')
                .map((item: any) => item.week_day)
                .sort((a: any, b: any) => {
                  return daysForSort[a] - daysForSort[b]
                })

              if (!!weekdays && weekdays.length) {
                return days.every((item: any) => weekdays.includes(item))
                  ? 'Menu stałe'
                  : weekdays.map((item: any) => t(`days:${item}`)).join(', ')
              }
            }
          }
          return {
            id,
            name: namePl,
            price: formatPrice(price),
            priceCSV: formatToExcelPrice(price),
            availability: visible
              ? t('menu_item_form:available')
              : t('menu_item_form:unavailable'),
            dietType: dietType ? t(`diet_types:${dietType}`) : '',
            category: lang === 'pl' ? category.titlePl : category.titleEn,
            visible,
            namePl,
            nameEn,
            descriptionPl,
            descriptionEn,
            rawPrice: price,
            unit: unit.name,
            amount,
            categoryId: category.id,
            categoryTitle: category.titlePl,
            diet: dietType,
            availabilityDate: availabilityDataDisplay(availability),
            rawAvailability: availability,
            isAvailable,
          }
        }
      ) || [],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, t, lang]
  )

  useEffect(() => {
    if (!!data?.queryRestaurant[0]) {
      setAvailable(
        data.queryRestaurant[0].menuItems
          .filter((item) => item.visible)
          .map((item) => item.id)
      )
    }
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <InfoText color={themeColors.error}>
        {data?.queryRestaurant[0]?.visible === undefined
          ? null
          : !data?.queryRestaurant[0].visible &&
            t('gastro:invisible_restaurant')}
      </InfoText>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        orderByProps="name"
        sortDesc={false}
        csvHeaders={csvHeaders}
        csvExport
        actionsComponent={(rowData: ITableRow) => (
          <ActionsWrapper
            container
            direction="row"
            justifyContent="space-between"
          >
            <Tooltip
              title={`${t('btn:edit')}`}
              data-e2e="gastro-table-button-edit-dish"
            >
              <IconButton
                size="small"
                onClick={() => setOpenedDetails(rowData)}
              >
                <FontAwesomeIcon
                  icon={faEdit as IconProp}
                  size="1x"
                  color={themeColors.primary}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`${t('btn:delete')}`}
              data-e2e="gastro-table-button-remove-dish"
            >
              <IconButton
                size="small"
                onClick={() => setItemToDelete(rowData.id)}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt as IconProp}
                  size="1x"
                  color={themeColors.error}
                />
              </IconButton>
            </Tooltip>
          </ActionsWrapper>
        )}
      />
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditMenuItem
          closeDetails={() => setOpenedDetails(undefined)}
          menuItem={openedDetails}
          updateList={refetch}
        />
      </BaseDrawer>
      <BaseDrawer open={openedAddForm} variant={'temporary'}>
        <AddMenuItem
          updateList={refetch}
          closeDrawer={closeAddForm}
          currentRestaurant={restaurant}
        />
      </BaseDrawer>
      <BaseModal
        confirmText={'btn:confirm'}
        open={!!itemToDelete}
        handleClose={() => setItemToDelete('')}
        handleAccept={() => handleDeleteMenuItem(itemToDelete)}
      >
        <ModalContent>{t('menu_item_form:delete_confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default MenuTable

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
const ItemNameText = styled.p<{ color?: string }>`
  font-weight: 500;
  margin: 3px 0;
  ${({ color }) => color && `color: ${color};`};
`
const ItemAmountText = styled.p<{ color?: string }>`
  font-weight: 500;
  color: ${(props) => props.color || themeColors.gray};
  margin: 0 0;
`
const NameCell = styled(Grid)`
  cursor: pointer;
`
const CalendarIcon = styled(CalendarTodayIcon)`
  margin-right: 8px;
`
const InfoText = styled(TextBody1)`
  padding: 16px;
`
const AvailabilityContainer = styled(Grid)`
  color: ${themeColors.success};
`
