import gql from 'graphql-tag'

export interface IBuilding {
  id: string
  name: string
}

export interface IQueryBuildingData {
  queryBuilding: IBuilding[]
}

export const QUERY_BUILDING = gql`
  query getBuilding {
    queryBuilding(order: { asc: sort }) {
      id
      name
    }
  }
`
