import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { ACCESS_PRIVILEGES_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  ListItemTextStyled,
  CustomListItemText,
  BadgeStyled,
  BadgeDot,
  LinkStyled,
} from '../Styles/NavListStyle'

interface ISKDListItemProps {
  accessCardIssuesCount?: number
  isMenuOpen?: boolean
}

const SKDListItem: FC<ISKDListItemProps> = ({
  accessCardIssuesCount,
  isMenuOpen,
}) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isSKDExpanded, setIsSKDExpanded] = useState(false)
  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(roles, ACCESS_PRIVILEGES_ROLES)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsSKDExpanded(!isSKDExpanded)}
        data-e2e="navigation-skd"
      >
        <ListItemIcon>
          <FAIcon icon={faIdCard as IconProp} />
        </ListItemIcon>
        <BadgeDot
          variant="dot"
          invisible={!accessCardIssuesCount}
          color="error"
        >
          <ListItemTextStyled primary={'SKD'} rpadding={125} />
        </BadgeDot>
        {isSKDExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isSKDExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.MOBILE_ACCESS_ACCOUNT_LIST}>
            <ListItem selected={isSelected(ROUTES.MOBILE_ACCESS_ACCOUNT_LIST)}>
              <ListItemText primary={t('skd:access_account_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.MOBILE_ACCESS_CARD_LIST}>
            <ListItem selected={isSelected(ROUTES.MOBILE_ACCESS_CARD_LIST)}>
              <ListItemText primary={t('skd:access_card_list')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.MOBILE_ACCESS_CARD_ISSUES}>
            <ListItem selected={isSelected(ROUTES.MOBILE_ACCESS_CARD_ISSUES)}>
              <BadgeStyled badgeContent={accessCardIssuesCount} color="error">
                <CustomListItemText primary={'SKD'} />
              </BadgeStyled>
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default SKDListItem
