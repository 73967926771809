import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import {
  DELETE_MOBILE_USER_ACCOUNT,
  IDeleteMobileUserAccountVars,
  IDeleteMobileUserAccountData,
} from '../../graphql/hid/deleteMobileUserAccount'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { toast } from 'react-toastify'
import { ITableRow } from './MobileAccessAccountTable'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { BaseButton } from '../Shared/BaseButton'
import styled from 'styled-components'
import ErrorContainer from '../Shared/ErrorContainer'

const DeleteMobileAccessAccount = ({
  data,
  refetch,
}: {
  data: ITableRow
  refetch: () => void
}) => {
  const { t } = useTranslation(['skd'])
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const { login } = data

  const onCompleted = (data: IDeleteMobileUserAccountData) => {
    const { status, error } = data.DeleteMobileUserAccount
    if (status) {
      successNotify('Success')
      refetch()
    } else error && setErrorMessage(error)
  }

  const [deleteMobileAccessAccount] = useMutation<
    IDeleteMobileUserAccountData,
    IDeleteMobileUserAccountVars
  >(DELETE_MOBILE_USER_ACCOUNT, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const deleteHIDAccount = () => {
    deleteMobileAccessAccount({ variables: { login } })
    setOpenModal(false)
  }

  return (
    <>
      <ButtonStyled
        icon={faTrashAlt as IconProp}
        onClick={() => setOpenModal(true)}
        customColor={themeColors.negative}
      >
        {t('delete_account')}
      </ButtonStyled>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={deleteHIDAccount}
      >
        <ModalContent>{`${t(
          'delete_account_confirmation'
        )}${login}?`}</ModalContent>
      </BaseModal>
    </>
  )
}

export default DeleteMobileAccessAccount

const ButtonStyled = styled(BaseButton)`
  margin-top: 16px;
`
