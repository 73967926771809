import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import { IFormError } from '../../types/FormTypes'

const DatePickerController = ({
  label,
  disablePast = false,
  control,
  errors,
  name,
  defaultValue,
  rules,
}: {
  label: string
  disablePast?: boolean
  control: any
  errors: IFormError | any
  name: string
  defaultValue?: Date | null
  rules?: Object
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Picker
          mask="__.__.____"
          label={label}
          value={value}
          onChange={onChange}
          InputAdornmentProps={{ position: 'start' }}
          inputFormat="DD.MM.YYYY"
          renderInput={(params) => (
            <Input {...params} color="primary" error={!!errors[name]} />
          )}
          minDate={disablePast ? new Date() : undefined}
        />
      )}
    />
  )
}

export default DatePickerController

const Input = styled(TextField)`
  background-color: white;
  max-width: 300px;
`
