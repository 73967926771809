import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'

const DatePicker = ({
  selectedDate,
  setSelectedDate,
  label,
  disablePast = false,
}: {
  selectedDate: Date | null
  setSelectedDate: (value: Date | null) => void
  label: string
  disablePast?: boolean
}) => {
  const handleChange = (date: Date | null) => setSelectedDate(date)

  return (
    <Picker
      mask="__.__.____"
      label={label}
      value={selectedDate}
      onChange={handleChange}
      InputAdornmentProps={{ position: 'start' }}
      inputFormat="DD.MM.YYYY"
      renderInput={(params) => <Input {...params} color="primary" />}
      minDate={disablePast ? new Date() : undefined}
    />
  )
}

export default DatePicker

const Input = styled(TextField)`
  background-color: white;
  max-width: 300px;
`
