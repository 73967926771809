import { useQuery } from '@apollo/client'
import {
  IQuerySurveyData,
  QUERY_SURVEY,
} from '../../graphql/survey/querySurvey'
import RTTable from '../../components/RTTable'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import styled from 'styled-components'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import EditSurvey from '../../components/SurveyEdit/EditSurvey'
import { BaseButton } from '../../components/Shared/BaseButton'
import AddSurvey from '../../components/SurveyAdd/AddSurvey'
import { Link } from 'react-router-dom'
import { dateIso2localeString } from '../../utils/formatDate'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import SurveyActivity from '../../components/SurveyDetails/SurveyActivity'
import DeleteSurvey from '../../components/SurveyDetails/DeleteSurvey'
import SurveyPreview from '../../components/SurveyDetails/SurveyPreview'

interface ITableColumns {
  Header: string
  accessor:
    | 'surveyName'
    | 'surveyId'
    | 'responses'
    | 'createdAt'
    | 'status'
    | 'preview'
}

interface ITableRow {
  surveyId: string
  surveyName: string
  createdAt: string
  responses: number
  status: boolean
  preview: boolean
}

const SurveyList = () => {
  const { t, i18n } = useTranslation(['survey'])
  const lang = i18n.language
  const [addSurvey, setAddSurvey] = useState(false)
  const [editSurvey, setEditSurvey] = useState('')

  const countSurveyResponses = (array: string[]) => [...new Set(array)].length

  const {
    data: survey,
    refetch,
    loading,
  } = useQuery<IQuerySurveyData>(QUERY_SURVEY, {
    fetchPolicy: 'no-cache',
  })

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('survey_name'),
        accessor: 'surveyName',
        Cell: (cellInfo: any) => (
          <Link
            to={{ pathname: `/admin/survey/${cellInfo.row.original.surveyId}` }}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ItemNameText>{cellInfo.row.original.surveyName}</ItemNameText>
          </Link>
        ),
      },
      {
        Header: t('survey_id'),
        accessor: 'surveyId',
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('responses'),
        accessor: 'responses',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo: any) => (
          <SurveyActivity
            surveyId={cellInfo.row.original.surveyId}
            surveys={survey?.querySurvey || []}
            refetch={refetch}
          />
        ),
      },
      {
        Header: t('preview'),
        accessor: 'preview',
        Cell: (cellInfo: any) => (
          <SurveyPreview
            surveyId={cellInfo.row.original.surveyId}
            surveys={survey?.querySurvey || []}
            refetch={refetch}
          />
        ),
      },
    ],
    [lang, t, survey, refetch]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      survey?.querySurvey.map(
        ({
          surveyId,
          surveyName,
          surveyNameEn,
          answers,
          createdAt,
          active,
          preview,
        }) => ({
          surveyId,
          surveyName: lang === 'pl' ? surveyName : surveyNameEn,
          createdAt,
          responses: countSurveyResponses(
            answers.map(({ user }) => user.login)
          ),
          status: active,
          preview,
        })
      ) || []
    )
  }, [survey, lang])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">
          {t('admin_menu:surveys')}
        </TypographyStyled>
        <BaseButton
          onClick={() => setAddSurvey(true)}
          data-e2e="add-survey"
          icon={faPlus as IconProp}
        >
          {t('btn:add_survey')}
        </BaseButton>
      </HeaderContainer>
      <RTTable
        columns={tableColumns}
        data={tableData}
        pagination
        isFiltersOff={true}
        actionsComponent={(rowData: any) => (
          <ActionsWrapper
            container
            direction="row"
            justifyContent="space-between"
          >
            <Tooltip title={`${t('btn:edit')}`}>
              <IconButton
                data-e2e="survey-edit"
                size="small"
                onClick={() => setEditSurvey(rowData.surveyId)}
              >
                <FontAwesomeIcon
                  icon={faEdit as IconProp}
                  size="1x"
                  color={themeColors.primary}
                />
              </IconButton>
            </Tooltip>
            <DeleteSurvey refetch={refetch} rowData={rowData} />
          </ActionsWrapper>
        )}
      />
      <BaseDrawer open={!!editSurvey} variant={'temporary'}>
        <EditSurvey
          closeDetails={() => setEditSurvey('')}
          surveyId={editSurvey}
          updateList={refetch}
        />
      </BaseDrawer>
      <BaseDrawer open={addSurvey} variant={'temporary'}>
        <AddSurvey
          closeDetails={() => setAddSurvey(false)}
          updateList={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default SurveyList

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
const ItemNameText = styled.p`
  font-weight: 500;
  margin: 3px 0;
  color: inherit;
  text-decoration: none;
`

const TypographyStyled = styled(Typography)`
  font-weight: 600;
`
