import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { TENANT_DOMAIN_CREATE_AND_ASSIGN } from '../../graphql/tenant/tenantDomainCreateAndAssign'
import { TENANT_DOMAIN_UNASSIGN } from '../../graphql/tenant/tenantDomainUnassign'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import BaseModal from '../Shared/BaseModal'
import EditTenantDomains from './EditTenantDomains'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { ITenant } from '../../graphql/tenant/getTenant'
import { toast } from 'react-toastify'

const TenantDataForm = ({
  tenantToEdit,
  openConfirmModal,
  setOpenConfirmModal,
  setOpenClickAwayModal,
  setDataFormTouched,
}: {
  tenantToEdit: ITenant
  openConfirmModal: boolean
  openClickAwayModal: boolean
  setOpenConfirmModal: (v: boolean) => void
  setOpenClickAwayModal: (v: boolean) => void
  setDataFormTouched: (v: boolean) => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [tenantDomains, setTenantDomains] = useState<string[]>([])
  const [domainsToUnassign, setDomainsToUnassign] = useState<string[]>([])
  const [domainsToAssign, setDomainsToAssign] = useState<string[]>([])
  const successNotify = (message: string) => toast.dark(message)

  const { t } = useTranslation(['form', 'btn', 'tenant', 'generic'])

  const onDomainAssignCompleted = (data: any) => {
    if (data.domainCreate.status) {
      successNotify(t('success'))
      setDomainsToAssign([])
    } else if (!data.domainCreate.status) {
      setErrorMessage(t(`code:${data.domainCreate.code}`))
    } else if (!data.tenantDomainAssign.status) {
      setErrorMessage(t(`code:${data.tenantDomainAssign.code}`))
    }
  }

  const onDomainUnassignCompleted = (data: any) => {
    if (data.tenantDomainUnassign.status) {
      successNotify(t('success'))
      setDomainsToUnassign([])
    } else if (!data.tenantDomainUnassign.status) {
      setErrorMessage(t(`code:${data.tenantDomainUnassign.code}`))
    }
  }

  const [tenantDomainCreateAndAssign] = useMutation(
    TENANT_DOMAIN_CREATE_AND_ASSIGN,
    {
      onCompleted: onDomainAssignCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    }
  )

  const [tenantDomainUnassign] = useMutation(TENANT_DOMAIN_UNASSIGN, {
    onCompleted: onDomainUnassignCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  useEffect(() => {
    if (tenantToEdit) {
      setTenantDomains(tenantToEdit.domains.map(({ name }) => name) || [])
    }
  }, [tenantToEdit])

  useEffect(() => {
    if (domainsToAssign.length || domainsToUnassign.length) {
      setDataFormTouched(true)
    } else setDataFormTouched(false)
  }, [domainsToUnassign, domainsToAssign, setDataFormTouched])

  const { name } = tenantToEdit

  const handleDomains = () => {
    domainsToUnassign.length &&
      domainsToUnassign.forEach((domain) => {
        tenantDomainUnassign({
          variables: {
            tenant: name,
            domain,
          },
        })
      })
    domainsToAssign.length &&
      domainsToAssign.forEach((domain) => {
        tenantDomainCreateAndAssign({
          variables: {
            tenant: name,
            domain,
          },
        })
      })
  }

  const handleSubmitChanges = () => {
    setOpenConfirmModal(false)
    if (domainsToUnassign.length || domainsToAssign.length) {
      handleDomains()
    }
  }

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
    setOpenConfirmModal(false)
  }

  return (
    <>
      <DetailsWrapper>
        <EditTenantDomains
          tenantDomains={tenantDomains}
          domainsToUnassign={domainsToUnassign}
          domainsToAssign={domainsToAssign}
          setTenantDomains={setTenantDomains}
          setDomainsToUnassign={setDomainsToUnassign}
          setDomainsToAssign={setDomainsToAssign}
        />
        <ButtonWrapper
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <BaseButton
            onClick={() => setOpenConfirmModal(true)}
            data-e2e="submit"
            disabled={!domainsToUnassign.length && !domainsToAssign.length}
          >
            {t('btn:save')}
          </BaseButton>
        </ButtonWrapper>
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </DetailsWrapper>

      <BaseModal
        confirmText={'btn:confirm'}
        open={openConfirmModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={handleSubmitChanges}
      >
        <ModalContent>{t('tenant:confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default TenantDataForm

const ButtonWrapper = styled(Grid)`
  padding: 16px;
`
