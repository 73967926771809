import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IDeleteMobileUserAccountVars {
  login: string
}

export interface IDeleteMobileUserAccountData {
  DeleteMobileUserAccount: IQueryBasicData
}

export const DELETE_MOBILE_USER_ACCOUNT = gql`
  mutation DeleteMobileUserAccount($login: String!) {
    DeleteMobileUserAccount(Login: $login) {
      status
      code
      error
      message
    }
  }
`
