import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../../components/RTTable'
import { IUser } from '../../graphql/users/queryUserList'
import RemoveSelectedUserRoles from './RemoveSelectedUserRoles'
import styled from 'styled-components'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { themeColors } from '../../const/colors'

interface ITableColumns {
  Header: string
  accessor:
    | 'fullName'
    | 'login'
    | 'tenantName'
    | 'disabled'
    | 'roles'
    | 'userRoles'
  filter: any
}

export interface ITableRow {
  fullName: string
  login: string
  tenantName: string
  disabled: string
  roles: string
  userRoles: string[]
}

const UserRolesTable = ({
  data,
  role,
  children,
  refetch,
}: {
  data: IUser[]
  role: string
  children: ReactNode
  refetch: () => void
}) => {
  const { t } = useTranslation(['columns', 'user_details', 'admin_menu'])
  const [selectedUsers, setSelectedUsers] = useState<ITableRow[]>([])

  const handleSelectRows = useCallback((selectedRowsData: ITableRow[]) => {
    console.log(selectedRowsData)
    setSelectedUsers(selectedRowsData)
  }, [])

  const csvHeaders = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: t('company'),
      key: 'tenantName',
    },
    {
      label: t('user_details:lock'),
      key: 'disabled',
    },
    {
      label: t('roles'),
      key: 'roles',
    },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('full_name'),
        accessor: 'fullName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('login'),
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('company'),
        accessor: 'tenantName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('user_details:lock'),
        accessor: 'disabled',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('roles'),
        accessor: 'roles',
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data.map(({ login, firstName, lastName, tenant, roles, disabled }) => {
        const activeRoles = roles.map(({ name }) => name)
        return {
          login,
          fullName: `${firstName} ${lastName}`,
          tenantName: tenant?.name || '',
          disabled: disabled ? t('bool:yes') : t('bool:no'),
          roles: activeRoles.join(', '),
          userRoles: activeRoles,
        }
      }) || []
    )
  }, [data, t])

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      rowSelect
      pagination
      csvExport
      csvHeaders={csvHeaders}
      orderByProps="fullName"
      sortDesc={false}
      onSelectionChange={handleSelectRows}
      filterDownloadCsvValue={t('bool:yes')}
      filterDownloadCsvKeys={'disabled'}
    >
      {children}
      {!!selectedUsers.length && (
        <Text color={themeColors.primary}>{`${selectedUsers.length} ${t(
          'generic:selected'
        )}`}</Text>
      )}
      <RemoveSelectedUserRoles
        users={selectedUsers}
        role={role}
        refetch={refetch}
      />
    </RTTable>
  )
}

export default UserRolesTable

const Text = styled(TextBody1Bold)`
  margin-right: 16px;
`
