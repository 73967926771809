import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseDrawer from '../Shared/BaseDrawer'
import RTTable from '../RTTable'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { dateIso2localeString } from '../../utils/formatDate'
import {
  QUERY_QUICK_PROMO,
  IQueryQuickPromoData,
  IQueryQuickPromoVars,
} from '../../graphql/quickPromo/queryQuickPromo'
import QuickPromoVisibility from './QuickPromoVisibility'
import QuickPromoDetails from './QuickPromoDetails'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import EditTableRow from '../Shared/EditTableRow'
import DeleteQuickPromo from './DeleteQuickPromo'
import { BaseButton } from '../../components/Shared/BaseButton'
import AddQuickPromo from './AddQuickPromo'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import SortQuickPromo from './SortQuickPromo'
import TooltipButton from '../../components/Shared/TooltipButton'

interface ITableColumns {
  Header: string
  accessor:
    | 'sort'
    | 'id'
    | 'companyName'
    | 'title'
    | 'dates'
    | 'validFrom'
    | 'validTo'
    | 'event'
    | 'visible'
}
export interface ITableRow {
  sort: number
  id: string
  visible: boolean
  companyName: string
  companyLogo: string
  title: string
  dates: string
  titleEn: string
  description: string
  descriptionEn: string
  instruction: string
  instructionEn: string
  location: string
  locationEn: string
  validFrom: string
  validTo: string
  event: boolean
  hasDiscount: boolean
  eventId?: string
  discount?: string
}

const QuickPromoTable: FC<{
  visible: boolean
}> = ({ visible }) => {
  const { t, i18n } = useTranslation(['promo'])
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [addQuickPromo, setAddQuickPromo] = useState(false)
  const [sortQuickPromo, setSortQuickPromo] = useState(false)

  const checkIfTimeIsZero = (date?: string) =>
    date && new Date(date).getHours() === 0 && new Date(date).getMinutes() === 0

  const formatDate = (date: string) =>
    checkIfTimeIsZero(date)
      ? dateIso2localeString(date, lang, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : dateIso2localeString(date, lang)

  const displayDate = (fromDate?: string, toDate?: string) =>
    toDate && fromDate
      ? `${formatDate(fromDate)} - ${formatDate(toDate)}`
      : toDate
      ? `Do ${formatDate(toDate)}`
      : fromDate
      ? `Od ${formatDate(fromDate)}`
      : 'Brak'

  const { data, loading, refetch } = useQuery<
    IQueryQuickPromoData,
    IQueryQuickPromoVars
  >(QUERY_QUICK_PROMO, {
    variables: {
      visible,
    },
    fetchPolicy: 'no-cache',
  })

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('order'),
        accessor: 'sort',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('columns:company'),
        accessor: 'companyName',
      },
      {
        Header: t('columns:title'),
        accessor: 'title',
      },
      {
        Header: t('dates'),
        accessor: 'dates',
      },
      {
        Header: t('promo:event_promo'),
        accessor: 'event',
        Cell: (props: any) => (!!props.value ? t('bool:yes') : t('bool:no')),
      },
      {
        Header: t('visibility'),
        accessor: 'visible',
        Cell: (cellInfo: any) => (
          <QuickPromoVisibility
            id={cellInfo.row.original.id}
            visible={cellInfo.row.original.visible}
            refetch={refetch}
          />
        ),
      },
    ],
    [t, refetch]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data?.queryQuickPromo.map(
        ({
          id,
          sort,
          visible,
          companyName,
          companyLogo,
          title,
          titleEn,
          description,
          descriptionEn,
          instruction,
          instructionEn,
          location,
          locationEn,
          validFrom,
          validTo,
          eventId,
          hasDiscount,
          discount,
        }) => ({
          sort,
          id,
          visible,
          companyName,
          companyLogo,
          title,
          titleEn,
          description,
          descriptionEn,
          instruction,
          instructionEn,
          location,
          locationEn,
          dates: displayDate(validFrom, validTo),
          validFrom,
          validTo,
          event: !!eventId,
          hasDiscount,
          eventId,
          discount,
        })
      ) || [],
    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ButtonStyled
        onClick={() => setAddQuickPromo(true)}
        icon={faPlus as IconProp}
        data-e2e="add-promo-button"
      >
        {t('add_promo')}
      </ButtonStyled>

      <RTTable
        columns={tableColumns}
        data={tableData}
        pagination
        sortDesc={false}
        orderByProps="sort"
        actionsComponent={(rowData: any) => (
          <ActionsWrapper
            container
            direction="row"
            justifyContent="space-between"
          >
            <EditTableRow onClick={() => setOpenedDetails(rowData)} />
            <DeleteQuickPromo
              id={rowData.id}
              visible={rowData.visible}
              refetch={refetch}
            />
          </ActionsWrapper>
        )}
      >
        <TooltipButton
          onClick={() => setSortQuickPromo(true)}
          hint=""
          disabled={!data?.queryQuickPromo.length}
          data-e2e="set-order-button"
        >
          {t('set_order')}
        </TooltipButton>
      </RTTable>
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        {openedDetails && (
          <QuickPromoDetails
            data={openedDetails}
            refetch={refetch}
            closeDetails={() => setOpenedDetails(undefined)}
          />
        )}
      </BaseDrawer>
      <BaseDrawer open={addQuickPromo} variant={'temporary'}>
        {data && (
          <AddQuickPromo
            refetch={refetch}
            closeDetails={() => setAddQuickPromo(false)}
            itemsAmount={data.queryQuickPromo.length}
          />
        )}
      </BaseDrawer>
      <BaseDrawer open={sortQuickPromo} variant={'temporary'}>
        <SortQuickPromo
          data={data?.queryQuickPromo}
          refetch={refetch}
          closeDetails={() => setSortQuickPromo(false)}
        />
      </BaseDrawer>
    </>
  )
}

export default QuickPromoTable
const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
const ButtonStyled = styled(BaseButton)`
  position: absolute;
  right: 0;
  top: 0;
`
