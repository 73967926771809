import React, { FC } from 'react'
import { useAppSelector } from '../../redux/store'
import { ListItemIcon } from '@mui/material'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useIsRouteSelected } from '../hooks/useIsRouteSelected'
import {
  MainListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IIntegrationsListItemProps {
  isMenuOpen?: boolean
}

const IntegrationsListItem: FC<IIntegrationsListItemProps> = ({
  isMenuOpen,
}) => {
  const { t } = useTranslation(['admin_menu'])
  const { isSelected } = useIsRouteSelected(ROUTES.INTEGRATIONS, !!isMenuOpen)

  const { isAdmin } = useAppSelector((state) => state.user)

  if (!isAdmin) {
    return null
  }

  return (
    <MainListItem selected={isSelected} data-e2e="navigation-integrations">
      <LinkStyled to={ROUTES.INTEGRATIONS}>
        <ListItemIcon>
          <FAIcon icon={faNetworkWired as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={t('integrations')} />
      </LinkStyled>
    </MainListItem>
  )
}

export default IntegrationsListItem
