import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  QUERY_LOG_LINE,
  IQueryLogLineData,
  IQueryLogLineVars,
} from '../../graphql/shared/queryLogLinesWithParameter'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useQuery } from '@apollo/client'
import { dateIso2localeString } from '../../utils/formatDate'
import RTTable from '../../components/RTTable'

interface ITableColumns {
  Header: string
  accessor: 'createdAt' | 'title' | 'body' | 'login' | 'target'
}

export interface ITableRow {
  createdAt: string
  title?: string
  body?: string
  login?: string
  target?: string
}

const PushListScreen = () => {
  const { t, i18n } = useTranslation(['push'])
  const lang = i18n.language

  const { data, loading } = useQuery<IQueryLogLineData, IQueryLogLineVars>(
    QUERY_LOG_LINE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: 'push.message',
      },
    }
  )

  const csvHeaders = [
    { label: t('createdAt'), key: 'createdAt' },
    { label: t('title'), key: 'title' },
    { label: t('body'), key: 'body' },
    { label: t('author'), key: 'author' },
    { label: t('target'), key: 'target' },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('createdAt'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          !!props.value && dateIso2localeString(props.value, lang),
      },
      {
        Header: t('title'),
        accessor: 'title',
      },
      {
        Header: t('body'),
        accessor: 'body',
      },
      {
        Header: t('author'),
        accessor: 'login',
      },
      {
        Header: 'Target',
        accessor: 'target',
        Cell: (props: { value: string }) => t(props.value.toLocaleLowerCase()),
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data?.queryLogLinesWithParameter.map(({ createdAt, parameters }) => ({
        title: parameters?.find(({ name }) => name === 'title')?.value,
        body: parameters?.find(({ name }) => name === 'body')?.value,
        login: parameters?.find(({ name }) => name === 'login')?.value,
        target: parameters?.find(({ name }) => name === 'target')?.value,
        createdAt,
      })) || [],
    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('push_list')}</TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        pagination
        csvExport
        rowSelect
        orderByProps="createdAt"
        csvHeaders={csvHeaders}
      />
    </>
  )
}

export default PushListScreen
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
