import { invariant } from "../../utilities/globals/index.js";
import { useContext } from 'react';
import { getApolloContext } from "../context/index.js";
export function useApolloClient(override) {
    var context = useContext(getApolloContext());
    var client = override || context.client;
    __DEV__ ? invariant(!!client, 'Could not find "client" in the context or passed in as an option. ' +
        'Wrap the root component in an <ApolloProvider>, or pass an ApolloClient ' +
        'instance in via options.') : invariant(!!client, 31);
    return client;
}
