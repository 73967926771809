import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  GET_USER_ACCEPTANCES,
  IGetUserAcceptancesEvents,
  IGetUserAcceptancesVars,
} from '../../graphql/users/getUserAcceptances'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { removeLinks } from '../../utils/removeLinks'

interface ITableColumns {
  Header: string
  accessor:
    | 'createdAt'
    | 'createdAt_csv'
    | 'service'
    | 'title'
    | 'required'
    | 'current'
    | 'content'
}

interface ITableRow {
  createdAt: string
  createdAt_csv: string
  service: string
  title: string
  required: string
  current: string
  content: string
}

const UserAcceptancesScreen = () => {
  const { t, i18n } = useTranslation()
  const { usr } = useParams()

  const [getUserAcceptances, { data, loading }] = useLazyQuery<
    IGetUserAcceptancesEvents,
    IGetUserAcceptancesVars
  >(GET_USER_ACCEPTANCES, {
    fetchPolicy: 'no-cache',
  })

  const csvHeaderToDownload = [
    'createdAt_csv',
    'service',
    'title',
    'required',
    'current',
    'content',
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('user_details_table:createdAt'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, i18n.language),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'createdAt_csv',
        accessor: 'createdAt_csv',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('user_details_table:service'),
        accessor: 'service',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('user_details_table:title'),
        accessor: 'title',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('user_details_table:required'),
        accessor: 'required',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('user_details_table:current'),
        accessor: 'current',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('user_details_table:content'),
        accessor: 'content',
        filter: 'filterRowsWithSubrows',
      },
    ],
    [i18n.language, t]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data?.getUser?.events?.map(({ created_at, regulation, comment }) => {
        const accepted = [
          'acceptance set to yes',
          'User changed acceptance to yes',
        ]
        const rejected = [
          'acceptance set to no',
          'User changed acceptance to no',
        ]
        const responseData = {
          createdAt: created_at ? created_at : '',
          createdAt_csv: created_at ? dateIso2ExcelFormat(created_at) : '',
          service: regulation?.service || '',
          title: regulation?.title || '',
          required: regulation?.required ? t('bool:yes') : t('bool:no'),
          current: accepted.includes(comment)
            ? t('bool:yes')
            : rejected.includes(comment)
            ? t('bool:no')
            : t('non_data:non_data'),
          content: removeLinks(regulation?.content || ''),
        }
        return responseData
      }) || [],
    [data, t]
  )

  useEffect(() => {
    if (usr) {
      getUserAcceptances({
        variables: {
          login: usr,
        },
      })
    }
  }, [usr])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {`${t('user_details_table:topic')} ${usr}`}
      </TypographyStyled>

      <RTTable
        columns={tableColumns}
        data={tableData}
        orderByProps="createdAt"
        csvExport
        pagination
        rowSelect
        csvHeaders={csvHeaderToDownload}
      />
    </>
  )
}

export default UserAcceptancesScreen

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
