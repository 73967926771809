import { useMemo, useState, FC, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import RTTable from '../RTTable'
import BaseDrawer from '../Shared/BaseDrawer'
import { getReservationStatusColor } from '../../utils/getStatusColor'
import ReservationTableActions from './ReservationTableActions'
import RentLockerManually from '../LockerRent/RentLockerManually'
import { LockerType, ILocker } from '../../graphql/lockers/queryLockers'
import ReservationSummary from '../LockerProtocols/ReservationSummary'
import { getCurrentActiveReservation } from '../../utils/getCurrentReservation'
import {
  ReservationStatus,
  IReservation,
} from '../../graphql/shared/sharedTypes'
import { IBuilding } from '../../graphql/lockers/queryLockerGroupReservation'
import styled from 'styled-components'
import TooltipButton from '../Shared/TooltipButton'
import { Grid } from '@mui/material'
import EditLockerReservation from './EditLockerReservation'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { LOCKER_ADMIN } from '../../const/permissions'
interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'buildingName'
    | 'name'
    | 'lockerName'
    | 'type'
    | 'reservationStatus'
    | 'leaseTerm'
    | 'validFrom'
    | 'validTo'
    | 'tenant'
    | 'user'
    | 'createdAt'
    | 'notes'
}
export interface ITableRow {
  id: string
  buildingName: string
  building: IBuilding
  name: string
  lockerName: string
  key: string
  transponder: string
  reservationStatus: ReservationStatus
  leaseTerm?: string
  tenant?: string
  tenantId?: string
  user: string
  login?: string
  notes?: string
  type: LockerType
  validFrom?: string
  validTo?: string
  email?: string
  createdAt?: string
  logs?: {
    createdAt: string
    message: string
  }[]
  reservation?: IReservation
  reservations: IReservation[]
}

const LockerReservationTable: FC<{
  lockers: ILocker[]
  children: ReactNode
  refetch: () => void
  building: string
  selectedRows: ITableRow[]
  setSelectedRows: (v: ITableRow[]) => void
}> = ({
  lockers,
  children,
  refetch,
  building,
  selectedRows,
  setSelectedRows,
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const [rentLockerManually, setRentLockerManually] = useState<ITableRow>()
  const [openedProtocol, setOpenedProtocol] = useState<ITableRow[]>([])
  const [pickUpProtocol, setPickUpProtocol] = useState(false)
  const [editLockerReservation, setEditLockerReservation] =
    useState<ITableRow>()
  const { roles } = useAppSelector((state) => state.user)

  const handleSelectRows = useCallback((selectedRowsData: ITableRow[]) => {
    setSelectedRows(selectedRowsData)
  }, [])

  const handleLockerReturnProtocol = () => {
    setPickUpProtocol(true)
    setOpenedProtocol(selectedRows)
  }

  const handleCloseProtocol = () => {
    setPickUpProtocol(false)
    setOpenedProtocol([])
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('building'),
      key: 'buildingName',
    },
    {
      label: t('locker_number'),
      key: 'name',
    },
    {
      label: 'Status',
      key: 'reservationStatus',
    },
    {
      label: t('from'),
      key: 'validFrom',
    },
    {
      label: t('to'),
      key: 'validTo',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: t('user'),
      key: 'user',
    },
    {
      label: t('created_at'),
      key: 'createdAt',
    },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: t('building'),
        accessor: 'buildingName',
      },
      {
        Header: t('locker_number'),
        accessor: 'name',
      },
      {
        Header: t('locker_type'),
        accessor: 'type',
        isVisible: false,
      },
      {
        Header: t('locker_type'),
        accessor: 'lockerName',
      },
      {
        Header: t('reservation_status'),
        accessor: 'reservationStatus',
        Cell: (props: { value: string }) => t(props.value),
      },
      {
        Header: t('lease_term'),
        accessor: 'leaseTerm',
      },
      {
        Header: t('from'),
        accessor: 'validFrom',
        Cell: (props: { value: string }) =>
          props.value && dateIso2ExcelFormat(props.value),
        isVisible: false,
      },
      {
        Header: t('to'),
        accessor: 'validTo',
        Cell: (props: { value: string }) =>
          props.value && dateIso2ExcelFormat(props.value),
        isVisible: false,
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
      },
      {
        Header: t('user'),
        accessor: 'user',
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          props.value && dateIso2ExcelFormat(props.value),
        isVisible: false,
      },
      {
        Header: t('notes'),
        accessor: 'notes',
        isVisible: false,
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      lockers.map(
        ({
          id,
          name,
          type,
          building,
          transponder,
          key,
          notes,
          reservations,
          logs,
          currentTenant,
          currentUser,
          currentReservation,
        }) => {
          const displayDate = (date: string) =>
            dateIso2localeString(date, lang, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          const reservation =
            currentReservation || getCurrentActiveReservation(reservations)
          return {
            id,
            buildingName: building.name,
            building,
            name,
            key,
            transponder,
            reservationStatus: reservation
              ? reservation.status
              : ReservationStatus.available,
            leaseTerm: reservation
              ? `${displayDate(reservation.validFrom)} - ${displayDate(
                  reservation.validTo
                )}`
              : '-',
            tenant: reservation?.tenant ? reservation.tenant?.name : '',
            tenantId: reservation?.tenant && reservation.tenant?.id,
            notes,
            lockerName: !!currentReservation?.order
              ? currentReservation.order?.product?.name
              : t(type),
            type,
            logs,
            email: reservation?.user
              ? reservation?.user?.email?.email
              : reservation?.tenant?.emails[0]?.email,
            validFrom: reservation?.validFrom,
            validTo: reservation?.validTo,
            user: reservation?.user
              ? `${reservation.user?.firstName} ${reservation.user?.lastName}`
              : '',
            login: reservation?.user?.login,
            createdAt: reservation?.createdAt,
            reservation,
            reservations,
          }
        }
      ) || []
    )
  }, [lockers, lang, t])

  return (
    <>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        csvExport
        csvHeaders={csvHeaders}
        onSelectionChange={handleSelectRows}
        isFiltersOff={true}
        orderByProps="createdAt"
        onRowClick={(rowData: ITableRow) => {
          checkRole(roles, LOCKER_ADMIN) &&
            rowData &&
            rowData.reservationStatus !== ReservationStatus.available &&
            setEditLockerReservation(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'reservationStatus'
            ? {
                style: {
                  color: getReservationStatusColor(
                    row.original.reservationStatus
                  ),
                  fontWeight: 600,
                },
              }
            : {}
        }
        actionsComponent={(rowData: ITableRow) =>
          checkRole(roles, LOCKER_ADMIN) && (
            <ReservationTableActions
              data={rowData}
              setRentLockerManually={setRentLockerManually}
              setOpenedProtocol={setOpenedProtocol}
              setPickUpProtocol={setPickUpProtocol}
            />
          )
        }
      >
        {children}
        {checkRole(roles, LOCKER_ADMIN) && (
          <Row>
            <TooltipButton
              onClick={handleLockerReturnProtocol}
              disabled={!building || !selectedRows.length}
              hint={t('protocol_button_hint')}
              data-e2e="selected-lockers-protocol-button"
            >
              {t('return_protocol')}
            </TooltipButton>
          </Row>
        )}
      </RTTable>
      <BaseDrawer
        open={
          !!rentLockerManually ||
          !!openedProtocol.length ||
          !!editLockerReservation
        }
        variant={'temporary'}
      >
        {!!rentLockerManually && (
          <RentLockerManually
            closeDetails={() => setRentLockerManually(undefined)}
            locker={rentLockerManually}
            refetch={refetch}
          />
        )}
        {!!openedProtocol.length && (
          <ReservationSummary
            closeDrawer={handleCloseProtocol}
            reservations={openedProtocol}
            pickUp={pickUpProtocol}
          />
        )}
        {!!editLockerReservation && (
          <EditLockerReservation
            closeDrawer={() => setEditLockerReservation(undefined)}
            lockerReservation={editLockerReservation}
            refetch={refetch}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default LockerReservationTable

const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
