import { useEffect, FC } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  IGetUserVars,
  IGetUsersData,
  GET_USER,
} from '../../graphql/users/getUser'
import UserInfo from './UserInfo'
import UserAccessCard from './UserAccessCard'
import UserTenant from './UserTenant'
import UserRoles from './UserRoles'
import FeatureSelect from './FeatureSelect'
import UserParkingData from './UserParkingData'
import UserAcceptances from './UserAcceptances'
import DrawerHeader from '../Shared/DrawerHeader'
import BlockUser from './BlockUser'
import AnonymizeUser from './AnonymizeUser'

const UserDetails: FC<{
  login: string
  closeDetails: () => void
}> = ({ login, closeDetails }) => {
  const { t } = useTranslation(['user_details'])

  const [getUser, { data, loading, refetch }] = useLazyQuery<
    IGetUsersData,
    IGetUserVars
  >(GET_USER, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    login && getUser({ variables: { login } })
  }, [login])

  if (loading || !data) {
    return <FullScreenLoader />
  }

  const user = data.getUser

  return (
    <>
      <DrawerHeader title={t('title')} handleClose={closeDetails}>
        <ButtonsRow>
          <BlockUser userData={user} refetch={refetch} modalConfirmation />
          <AnonymizeUser userData={user} refetch={refetch} />
        </ButtonsRow>
      </DrawerHeader>
      <UserInfo data={user} refetch={refetch} />
      <Row>
        <UserTenant userData={user} refetch={refetch} />
        <UserAccessCard userData={user} refetch={refetch} />
      </Row>
      <UserRoles userData={user} refetch={refetch} />
      <FeatureSelect
        login={user.login}
        features={user.features}
        refetch={refetch}
      />
      <UserParkingData carPlates={user.carPlates} />
      <UserAcceptances login={user.login} />
    </>
  )
}

const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
`
const ButtonsRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
export default UserDetails
