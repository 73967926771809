import { Route, Routes } from 'react-router'
import { ApolloError, useQuery } from '@apollo/client'
import Navigation from './components/Navigation'
import AuthWrapper from './components/AuthWrapper'
import { ROUTES } from './const/routes'
import {
  AGGREGATE_ISSUES_AND_EMAIL_VERIFICATION_REQUESTS,
  IAggregateIssuesAndEmailVerificationRequestsData,
} from './graphql/userIssues/aggregateIssues'
import {
  AGGREGATE_ACCESS_CARD_ISSUES,
  IAggregateAccessCardIssuesData,
} from './graphql/users/aggregateAccessCardIssues'
import Home from './screens/Home'
import ResidentIssuesList from './screens/Users/ResidentIssuesList'
import NoMatch from './components/NoMatch'
import {
  GET_MY_PROFILE_QUERY,
  IGetMyProfileData,
  IMyProfile,
} from './graphql/admin/getMyProfile'
import { UserActionTypes } from './redux/user/types/UserActionTypes'
import { useDispatch } from 'react-redux'
import FullScreenLoader from './components/Shared/FullScreenLoader'
import {
  newMessageIssuesFilter,
  newTenantIssuesFilter,
} from './graphql/userIssues/queryIssue'
import TenantsList from './screens/Tenants/TenantsList'
import UnconfirmedEmailsList from './screens/Users/UnconfirmedEmailsList'
import UsersMessageList from './screens/Users/UsersMessageList'
import GardenTicketsList from './screens/Garden/GardenTicketsList'
import MenuList from './screens/Gastro/MenuList'
import UserAcceptancesScreen from './screens/Users/UserAcceptancesScreen'
import PaymentHistory from './screens/Parking/PaymentHistory'
import ParkingPassesList from './screens/Parking/ParkingPassesList'
import ParkingList from './screens/Parking/ParkingList'
import Integration from './screens/Integrations/Integration'
import SurveyDetails from './screens/Survey/SurveyDetailsScreen'
import SurveyList from './screens/Survey/SurveyList'
import BlockingOlivkaProducts from './screens/Olivka/BlockingOlivkaProducts'
import SoldSubscriptionList from './screens/Parking/SoldSubscriptionList'
import {
  RESTAURANT_ROLES,
  PARKING_ROLES,
  ADMIN,
  SURVEY_ROLES,
  LOCKER_ROLES,
  GETRESPONSE_ROLES,
  PUSH_ROLES,
  PAYU_ROLES,
  ACCESS_PRIVILEGES_ROLES,
  BANNER,
  PROMO_ROLES,
  ISSUE_ROLES,
} from './const/permissions'
import LockerTabs from './screens/Lockers/LockerTabs'
import LockerProductsList from './screens/Lockers/LockerProductsList'
import SoldOneTimeTicketList from './screens/Parking/SoldOneTimeTicketList'
import ParkingPlacesList from './screens/Parking/ParkingPlacesList'
import ParkingPlaceReservationList from './screens/Parking/ParkingPlaceReservationList'
import GroupReservationList from './screens/Lockers/GroupReservationList'
import LockerReservationList from './screens/Lockers/LockerReservationList'
import LockerPaymentHistory from './screens/Lockers/LockerPaymentHistory'
import LockerStatistics from './screens/Lockers/LockerStatistics'
import ParkingOccupancyReport from './screens/Parking/ParkingOccupancyReport'
import ParkingPoolOccupancyReport from './screens/Parking/ParkingPoolOccupancyReport'
import SynchronizationScreen from './screens/GetResponse/SynchronizationScreen'
import EmailList from './screens/GetResponse/EmailList'
import EventTicketList from './screens/Events/EventTicketList'
import EventSalesSystem from './screens/Events/EventSalesSystem'
import DeleteAccountRequestsList from './screens/Users/DeleteAccountRequestsList'
import EventReports from './screens/Events/EventReports'
import PushScreen from './screens/Push/PushScreen'
import TenantParkingReservations from './screens/Parking/TenantParkingReservations'
import PushListScreen from './screens/Push/PushListScreen'
import PayuPayout from './screens/PayU/PayuPayout'
import MobileAccessCardIssuesList from './screens/HID/MobileAccessCardIssuesList'
import BannerList from './screens/Communication/BannerList'
import MobileAccessCardList from './screens/HID/MobileAccessCardList'
import MobileAccessAccountList from './screens/HID/MobileAccessAccountList'
import UserRolesList from './screens/Users/UserRolesList'
import ParkingManagementReport from './screens/Parking/ParkingManagementReport'
import TenantParkingPlacesList from './screens/Parking/TenantParkingPlacesList'
import UserList from './screens/Users/UserList'
import QuickPromoList from './screens/QuickPromo/QuickPromoList'
import CarPlateVerificationReport from './screens/Parking/CarPlateVerificationReport'

const AdminApp = () => {
  const dispatch = useDispatch()

  const setUserData = ({
    firstName,
    lastName,
    login,
    tenant,
    language,
    roles,
    managesRestaurants,
    email,
    workEmail,
  }: IMyProfile) =>
    dispatch({
      type: UserActionTypes.SET_USER_DATA,
      data: {
        firstName,
        lastName,
        login,
        tenant: !!tenant?.name,
        language,
        roles: roles.map((role) => role.name),
        managesRestaurants,
        email: email?.email,
        workEmail: workEmail?.email,
      },
    })

  const onCompleted = ({ getMyProfile }: IGetMyProfileData) => {
    if (getMyProfile.status) {
      setUserData(getMyProfile.user)
    }
  }

  const { loading } = useQuery<IGetMyProfileData>(GET_MY_PROFILE_QUERY, {
    onCompleted,
    onError: (error: ApolloError) => console.log(error.message),
    fetchPolicy: 'no-cache',
  })

  const { data, refetch } =
    useQuery<IAggregateIssuesAndEmailVerificationRequestsData>(
      AGGREGATE_ISSUES_AND_EMAIL_VERIFICATION_REQUESTS,
      {
        variables: {
          filter: newTenantIssuesFilter,
          filter2: newMessageIssuesFilter,
        },
        fetchPolicy: 'no-cache',
      }
    )

  const {
    data: newAccessCardIssuesCount,
    refetch: refetchAccessCardIssuesCount,
  } = useQuery<IAggregateAccessCardIssuesData>(AGGREGATE_ACCESS_CARD_ISSUES, {
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Navigation
      tenantRequestCount={data?.aggregateIssue?.count}
      emailVerificationRequestCount={
        data?.aggregateEmailVerificationRequest?.count
      }
      userMessagesCount={data?.aggregateMessages?.messagesCount}
      accessCardIssuesCount={newAccessCardIssuesCount?.aggregateIssue.count}
    >
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />

        <Route
          path={`${ROUTES.GARDEN_TICKETS}/:usr?`}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <GardenTicketsList />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={ROUTES.GASTRO_MENU}
          element={
            <AuthWrapper requiredRoles={RESTAURANT_ROLES}>
              <MenuList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_VALID_SUBSCRIPTIONS_WITH_DETAILS}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <SoldSubscriptionList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_SOLD_ONE_TIME_TICKETS}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <SoldOneTimeTicketList />
            </AuthWrapper>
          }
        />
        <Route
          path={`${ROUTES.PARKING_PAYMENT_HISTORY}/:usr?`}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <PaymentHistory />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_SUBSCRIPTIONS_PRODUCTS_LIST}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingPassesList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_LIST}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_PLACES}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingPlacesList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_TENANT_PLACES}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <TenantParkingPlacesList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_RESERVATIONS}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingPlaceReservationList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_OCCUPANCY_REPORT}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingOccupancyReport />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.TENANT_PARKING}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <TenantParkingReservations />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_POOL_OCCUPANCY_REPORT}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingPoolOccupancyReport />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_MANAGEMENT_REPORT}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <ParkingManagementReport />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.CAR_PLATE_VERIFICATION_REPORT}
          element={
            <AuthWrapper requiredRoles={PARKING_ROLES}>
              <CarPlateVerificationReport />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.SURVEY_HOME}
          element={
            <AuthWrapper requiredRoles={SURVEY_ROLES}>
              <SurveyList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.LOCKER_RESERVATIONS}
          element={
            <AuthWrapper requiredRoles={LOCKER_ROLES}>
              <LockerReservationList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.LOCKERS_WITH_STATUS}
          element={
            <AuthWrapper requiredRoles={LOCKER_ROLES}>
              <LockerTabs />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.LOCKER_PRODUCTS}
          element={
            <AuthWrapper requiredRoles={LOCKER_ROLES}>
              <LockerProductsList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.LOCKER_GROUP_RESERVATIONS}
          element={
            <AuthWrapper requiredRoles={LOCKER_ROLES}>
              <GroupReservationList />
            </AuthWrapper>
          }
        />
        <Route
          path={`${ROUTES.LOCKER_PAYMENT_HISTORY}/:usr?`}
          element={
            <AuthWrapper requiredRoles={LOCKER_ROLES}>
              <LockerPaymentHistory />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.LOCKER_STATISTICS}
          element={
            <AuthWrapper requiredRoles={LOCKER_ROLES}>
              <LockerStatistics />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.SURVEY_DETAILS}
          element={
            <AuthWrapper requiredRoles={SURVEY_ROLES}>
              <SurveyDetails />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.EVENT_TICKETS}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <EventTicketList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.EVENTS_SALES_SYSTEM}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <EventSalesSystem />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.EVENTS_REPORTS}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <EventReports />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PROMOS}
          element={
            <AuthWrapper requiredRoles={PROMO_ROLES}>
              <QuickPromoList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.USERS_TENANT_ISSUES}
          element={
            <AuthWrapper requiredRoles={ISSUE_ROLES}>
              <ResidentIssuesList
                tenantRequestCount={data?.aggregateIssue?.count}
                updateAggregatedCount={refetch}
              />
            </AuthWrapper>
          }
        />
        {/* <Route
          path={ROUTES.USERS_LIST}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <UsersList />
            </AuthWrapper>
          }
        /> */}
        <Route
          path={ROUTES.USER_LIST_WITH_DETAILS}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <UserList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.USER_ROLES_LIST}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <UserRolesList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.USER_ACCEPTANCES_TABLE}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <UserAcceptancesScreen />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.UNCONFIRMED_EMAILS_LIST}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <UnconfirmedEmailsList
                emailVerificationRequestCount={
                  data?.aggregateEmailVerificationRequest?.count
                }
              />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.USERS_COMPANIES}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <TenantsList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.DELETE_ACCOUNT_REQUESTS_LIST}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <DeleteAccountRequestsList />
            </AuthWrapper>
          }
        />
        <Route
          path={`${ROUTES.MESSAGES}/:usr?`}
          element={
            <AuthWrapper requiredRoles={ISSUE_ROLES}>
              <UsersMessageList
                userMessagesCount={data?.aggregateMessages?.messagesCount}
                updateAggregatedCount={refetch}
              />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.INTEGRATIONS}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <Integration />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.OLIVKA}
          element={
            <AuthWrapper requiredRoles={ADMIN}>
              <BlockingOlivkaProducts />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.GETRESPONSE_SYNCHRONIZATION}
          element={
            <AuthWrapper requiredRoles={GETRESPONSE_ROLES}>
              <SynchronizationScreen />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.GETRESPONSE_EMAIL_LIST}
          element={
            <AuthWrapper requiredRoles={GETRESPONSE_ROLES}>
              <EmailList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.SEND_PUSH}
          element={
            <AuthWrapper requiredRoles={PUSH_ROLES}>
              <PushScreen />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PUSH_LIST}
          element={
            <AuthWrapper requiredRoles={PUSH_ROLES}>
              <PushListScreen />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PAYU_PAYOUT}
          element={
            <AuthWrapper requiredRoles={PAYU_ROLES}>
              <PayuPayout />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.MOBILE_ACCESS_CARD_ISSUES}
          element={
            <AuthWrapper requiredRoles={ACCESS_PRIVILEGES_ROLES}>
              <MobileAccessCardIssuesList
                accessCardIssuesCount={
                  newAccessCardIssuesCount?.aggregateIssue.count
                }
                refetchAccessCardIssuesCount={refetchAccessCardIssuesCount}
              />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.MOBILE_ACCESS_CARD_LIST}
          element={
            <AuthWrapper requiredRoles={ACCESS_PRIVILEGES_ROLES}>
              <MobileAccessCardList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.MOBILE_ACCESS_ACCOUNT_LIST}
          element={
            <AuthWrapper requiredRoles={ACCESS_PRIVILEGES_ROLES}>
              <MobileAccessAccountList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.COMMUNICATION_BANNER}
          element={
            <AuthWrapper requiredRoles={BANNER}>
              <BannerList />
            </AuthWrapper>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Navigation>
  )
}

export default AdminApp
