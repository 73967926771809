import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../RTTable'
import { IOrigoCard } from '../../graphql/hid/queryOrigoCard'
import { themeColors } from '../../const/colors'
import DeleteMobileAccessCard from './DeleteMobileAccessCard'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'cardNumber'
    | 'status'
    | 'userId'
    | 'accessGranted'
    | 'login'
    | 'device'
}
export interface ITableRow {
  id: string
  status: string
  cardNumber: number
  userId: string
  accessGranted: boolean
  login: string
  device: string
}

const MobileAccessCardTable: FC<{
  data: IOrigoCard[]
  refetch: () => void
}> = ({ data, refetch }) => {
  const { t } = useTranslation(['skd'])

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('card_number'),
        accessor: 'cardNumber',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'User Id',
        accessor: 'userId',
      },
      {
        Header: t('access_granted'),
        accessor: 'accessGranted',
        Cell: (props: any) => (!!props.value ? t('bool:yes') : t('bool:no')),
      },
      {
        Header: t('columns:login'),
        accessor: 'login',
      },
      {
        Header: t('device'),
        accessor: 'device',
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data.map(
        ({
          id,
          status,
          cardNumber,
          userId,
          accessGranted,
          deviceDescription,
          origoAccount,
        }) => ({
          id,
          cardNumber: Number(cardNumber),
          status,
          userId,
          accessGranted,
          login: !!origoAccount?.user ? origoAccount.user.login : 'Brak',
          device: deviceDescription,
        })
      ) || [],
    [data]
  )

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      // onRowClick={(rowData) => setOpenedIssue(rowData)}
      pagination
      csvExport
      rowSelect
      orderByProps="cardNumber"
      getCellProps={({ column, row }) =>
        column.id === 'accessGranted'
          ? {
              style: {
                color: row.original.accessGranted
                  ? themeColors.accept
                  : themeColors.error,
                fontWeight: 600,
              },
            }
          : {}
      }
      // actionsComponent={(rowData: ITableRow) => (
      //   <DeleteMobileAccessCard data={rowData} refetch={refetch} />
      // )}
    />
  )
}

export default MobileAccessCardTable
