import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  date2isoString,
  date2isoStringWithTimezone,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import DateRangeParkingOccupancyFilter from '../../components/Parking/DateRangeParkingOccupancyFilter'
import {
  PARKING_MANAGEMENT_DAILY_USAGE_STAT,
  IParkingManagementDailyUsageStatData,
  IParkingManagementDailyUsageStatVars,
} from '../../graphql/parking/statParkingManagementDailyUsage'
import {
  QUERY_TENANT_PARKING_POOL,
  IQueryTenantParkingPoolData,
} from '../../graphql/tenant/queryTenantParkingPool'
import {
  QUERY_PARKING_AVAILABLE_PLACES,
  IQueryParkingAvailablePlacesData,
} from '../../graphql/parking/queryParkingAvailablePlaces'
import { themeColors } from '../../const/colors'
import ErrorContainer from '../../components/Shared/ErrorContainer'

interface ITableColumns {
  Header: string
  accessor: 'date' | 'dateCSV' | 'parking' | 'rented' | 'available'
}

export interface ITableRow {
  date: string
  dateCSV: string
  parking: string
  rented: number
  available?: number
}

const now = new Date()
const start = new Date(now.setDate(now.getDate() - 30))
const end = new Date()

const ParkingOccupancyReport = () => {
  const { t, i18n } = useTranslation(['parking_list'])
  const lang = i18n.language
  const [rangeFrom, setRangeFrom] = useState<Date | null>(start)
  const [rangeTo, setRangeTo] = useState<Date | null>(end)
  const [error, setError] = useState('')

  const csvHeaders = [
    {
      label: 'Data',
      key: 'dateCSV',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: 'Wynajęte',
      key: 'rented',
    },
    {
      label: 'Dostępne do wynajmu',
      key: 'available',
    },
  ]

  const { data: tenants, loading: tenantsLoading } =
    useQuery<IQueryTenantParkingPoolData>(QUERY_TENANT_PARKING_POOL, {
      fetchPolicy: 'no-cache',
    })

  const { data: places, loading: placesLoading } =
    useQuery<IQueryParkingAvailablePlacesData>(QUERY_PARKING_AVAILABLE_PLACES, {
      fetchPolicy: 'no-cache',
    })

  const onCompleted = (data: IParkingManagementDailyUsageStatData) => {
    const { code, status, error } = data.parkingDailyUsage
    const {
      code: code2,
      status: status2,
      error: error2,
    } = data.parkingPoolDailyUsage
    if (!status || !status2) {
      !!code &&
        setError(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
        )
      !!code2 &&
        setError(
          t(`code:${code2}`) === `${code2}` ? `${error2}` : t(`code:${code2}`)
        )
    } else setError('')
  }

  const [getParkingStats, { data, loading }] = useLazyQuery<
    IParkingManagementDailyUsageStatData,
    IParkingManagementDailyUsageStatVars
  >(PARKING_MANAGEMENT_DAILY_USAGE_STAT, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      setError(error.message)
    },
    onCompleted,
  })

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'date',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
      },
      {
        Header: t('date'),
        accessor: 'dateCSV',
        isVisible: false,
      },
      {
        Header: 'Parking',
        accessor: 'parking',
      },
      {
        Header: t('rented'),
        accessor: 'rented',
      },
      {
        Header: t('available_for_rent'),
        accessor: 'available',
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(() => {
    const stats = !!data
      ? [...data.parkingDailyUsage.stats, ...data.parkingPoolDailyUsage.stats]
      : []
    return (
      stats.map(({ date, parking, sold, tenant }) => ({
        date,
        dateCSV: dateIso2ExcelFormat(date),
        parking: tenant ? `${parking} ${tenant}` : parking,
        rented: sold,
        available: tenant
          ? tenants?.queryTenant.find(({ name }) => name === tenant)
              ?.parkingPool?.placesAggregate.count
          : Number(
              places?.queryParking.find(({ name }) => name === parking)?.places
                .length
            ),
      })) || []
    )
  }, [data, tenants, places])

  useEffect(() => {
    if (rangeFrom && rangeTo) {
      getParkingStats({
        variables: {
          from: date2isoStringWithTimezone(new Date(rangeFrom)),
          to: date2isoString(new Date(rangeTo)),
          tenant: '*',
          parkingName: '',
        },
      })
    }
  }, [rangeFrom, rangeTo])

  if (loading || tenantsLoading || placesLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:parking_management_report')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        orderByProps="date"
        sortDesc={false}
        csvExport
        csvHeaders={csvHeaders}
        getCellProps={(cellInfo) =>
          cellInfo.column.id === 'rented' &&
          !!cellInfo.row.original.available &&
          cellInfo.value >= Number(cellInfo.row.original.available) - 2
            ? {
                style: {
                  color: themeColors.yellow,
                  fontWeight: 600,
                },
              }
            : {}
        }
      >
        <DateRangeParkingOccupancyFilter
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          setRangeFrom={setRangeFrom}
          setRangeTo={setRangeTo}
        />
      </RTTable>
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default ParkingOccupancyReport

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
