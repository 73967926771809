import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Grid, Paper } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { IOrigoCard } from '../../graphql/hid/queryOrigoCard'

const AccessCard: FC<{
  data: IOrigoCard
  key: string
}> = ({ data, key }) => {
  const { t } = useTranslation(['skd'])
  const {
    cardNumber,
    accessGranted,
    privilegeRequested,
    privilegeGranted,
    deviceDescription,
    userId,
  } = data

  const convertBool = (v: boolean) => (v ? t('bool:yes') : t('bool:no'))

  const cardData = [
    { label: t('card_number'), value: cardNumber },
    { label: 'User ID', value: userId },
    { label: t('access_granted'), value: convertBool(accessGranted) },
    { label: t('privilege_requested'), value: convertBool(privilegeRequested) },
    { label: t('privilege_granted'), value: convertBool(privilegeGranted) },
    { label: t('device'), value: deviceDescription },
  ]

  return (
    <PaperSection key={key}>
      <Grid>
        {cardData.map(
          (item, i) =>
            !!item.value && (
              <Row key={i}>
                <DetailText>{`${item.label}: `}</DetailText>
                <DetailBold>{item.value}</DetailBold>
              </Row>
            )
        )}
      </Grid>
    </PaperSection>
  )
}

export default AccessCard

const PaperSection = styled(Paper).attrs(() => ({ variant: 'outlined' }))`
  padding: 12px;
  &:not(:last-of-type) {
    margin-bottom: 18px;
  }
`
const Row = styled(Grid)`
  padding: 4px 6px;
`
const DetailText = styled(TextBody2Medium)`
  display: inline;
`
const DetailBold = styled(TextBody2Bold)`
  display: inline;
`
