import gql from 'graphql-tag'
import { IPayment, ILog } from '../shared/sharedTypes'

export enum OneTimeParkingTicketStatus {
  new = 'new',
  reserved = 'reserved',
  processing = 'processing',
  paid = 'paid',
  cancelled = 'cancelled',
  unavailable = 'unavailable',
  error = 'error',
}

export interface OneTimeParkingTicket {
  id: string
  ticketNumber: string
  amanoTicketNo: string
  validFrom: string
  validTo: string
  createdAt: string
  carPlate: string
  status: OneTimeParkingTicketStatus
  price: number
  parkingName: string
  payment: IPayment
  user: {
    login: string
    firstName: string
    lastName: string
    email: {
      email: string
    }
    tenant: {
      name: string
    }
  }
  logs: ILog[]
}

export interface IQueryOneTimeParkingTicketData {
  tickets: OneTimeParkingTicket[]
}

export const QUERY_ONE_TIME_PARKING_TICKET = gql`
  query queryOneTimeParkingTicket {
    tickets: queryOneTimeParkingTicket {
      id
      ticketNumber: ticket_number
      amanoTicketNo: amano_ticketno
      validFrom: valid_from
      validTo: valid_to
      createdAt: created_at
      carPlate: car_plate
      status
      price
      parkingName: parking_name
      payment {
        paymentId: id
        sessionId: session_id
        createdAt: created_at
        status
        externalId: external_order_id
        logs {
          message
          createdAt: created_at
        }
      }
      user {
        login
        firstName: first_name
        lastName: last_name
        email {
          email
        }
        tenant {
          name
        }
      }
      logs {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
    }
  }
`
