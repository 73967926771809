import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Title } from '../Styles/TextCustom'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid, Alert, Button } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import {
  UPDATE_PARKING_POOL,
  IUpdateParkingPoolData,
  IUpdateParkingPoolVariables,
} from '../../graphql/tenant/updateParkingPool'
import ParkingSelect from '../Parking/ParkingSelect'
import ParkingPoolInputs from '../Tenant/ParkingPoolInputs'
import { toast } from 'react-toastify'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { ITenant } from '../../graphql/tenant/getTenant'
import { useNavigate } from 'react-router-dom'
import BaseModal from '../Shared/BaseModal'

type SharedParkingFormData = {
  productId: number
  maxActiveUserCount: number
  maxAdvancedDays: number
  maxDurationDays: number
  placesCountContract: number
  maxReservationCountPerUser: number
  notifyUserNearingReservation: boolean
  customEmailMessage: string
  reservationNearingInHours: number
  notifyOfficeManagersNewReservation: boolean
}

const EditParkingPoolForm = ({
  tenantToEdit,
  setParkingPoolFormTouched,
  refetch,
}: {
  tenantToEdit: ITenant
  setParkingPoolFormTouched: (v: boolean) => void
  refetch: () => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [parking, setParking] = useState('')
  const { t } = useTranslation(['parking_pool'])
  const successNotify = (message: string) => toast.dark(message)
  const { id, parkingPass, isValid } = tenantToEdit.parkingPool || {}
  const navigate = useNavigate()

  useEffect(() => {
    if (parkingPass) {
      setParking(parkingPass.parking.name)
    }
  }, [parkingPass])

  const {
    control,
    handleSubmit: handleParkingSubmit,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<SharedParkingFormData>()

  const onCompleted = (data: IUpdateParkingPoolData) => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
  }

  const [updateParkingPool] = useMutation<
    IUpdateParkingPoolData,
    IUpdateParkingPoolVariables
  >(UPDATE_PARKING_POOL, {
    onCompleted,
    onError: (error: ApolloError) => {
      setErrorMessage(error.message)
    },
  })

  const onParkingSubmit = async (data: SharedParkingFormData) => {
    id &&
      (await updateParkingPool({
        variables: {
          id,
          maxActiveUserCount: Number(data.maxActiveUserCount),
          maxAdvanceDays: Number(data.maxAdvancedDays),
          maxDurationDays: Number(data.maxDurationDays),
          maxReservationCountPerUser: Number(data.maxReservationCountPerUser),
          placesCountContract: Number(data.placesCountContract),
          notifyUserNearingReservation: data.notifyUserNearingReservation,
          reservationNearingInHours:
            Number(data.reservationNearingInHours) || 0,
          customEmailMessage: data.customEmailMessage || '',
          notifyOfficeManagersNewReservation:
            data.notifyOfficeManagersNewReservation,
        },
      }))
  }

  const goToPlaces = () =>
    navigate('/admin/parking/places', {
      state: {
        prop: { parkingProp: parking, parkingPool: true },
      },
      replace: true,
    })

  const formTouched = !!Object.keys(dirtyFields).length

  useEffect(() => {
    formTouched && setParkingPoolFormTouched(true)
  }, [formTouched])

  useEffect(() => {
    if (!isValid) {
      setOpenModal(true)
    }
  }, [isValid])

  return (
    <>
      <DetailsWrapper>
        {!isValid && (
          <AlertStyled
            severity="error"
            action={
              <Button color="inherit" size="small" onClick={goToPlaces}>
                {t('go_to_places')}
              </Button>
            }
          >
            {t('no_valid')}
          </AlertStyled>
        )}
        <Title>{t('form:shared_parking_feature')}</Title>
        <form onSubmit={handleParkingSubmit(onParkingSubmit)}>
          <SelectWrapper>
            <ParkingSelect setValue={setParking} value={parking} disabled />
          </SelectWrapper>
          <ParkingPoolInputs
            tenant={tenantToEdit}
            control={control}
            errors={errors}
            watch={watch}
          />
          <ButtonWrapper
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <BaseButton
              type="submit"
              disabled={!parking}
              data-e2e="parking-pool-submit"
            >
              {t('btn:save')}
            </BaseButton>
          </ButtonWrapper>
        </form>
      </DetailsWrapper>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseModal
        cancelText={t('do_it_later')}
        confirmText={t('do_it_now')}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={goToPlaces}
      >
        <ModalContent>
          {t('add_places_hint', { tenant: tenantToEdit.name, parking })}
        </ModalContent>
      </BaseModal>
    </>
  )
}

export default EditParkingPoolForm

const ButtonWrapper = styled(Grid)`
  padding: 16px;
`
const SelectWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`
const AlertStyled = styled(Alert)`
  margin-bottom: 16px;
`
const ModalContent = styled(Grid)`
  font-size: 16px;
  text-align: center;
  padding: 30px;
  font-weight: 500;
  height: 200px;
  max-width: 500px;
`
