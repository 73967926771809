import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@mui/material'
import styled from 'styled-components'

const DateRangeFilter = ({
  rangeFrom,
  rangeTo,
  setRangeFrom,
  setRangeTo,
  labelFrom,
  labelTo,
}: {
  rangeFrom: Date | null
  setRangeFrom: (value: Date | null) => void
  rangeTo: Date | null
  setRangeTo: (value: Date | null) => void
  labelFrom?: string
  labelTo?: string
}) => {
  const { t } = useTranslation(['lockers'])
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Picker
          mask="__.__.____"
          label={labelFrom ? labelFrom : t('from')}
          value={rangeFrom}
          onChange={setRangeFrom}
          InputAdornmentProps={{ position: 'start' }}
          inputFormat="DD.MM.YYYY"
          renderInput={(params) => <Input {...params} color="primary" />}
        />
      </Grid>
      <Grid item>
        <Picker
          mask="__.__.____"
          label={labelTo ? labelTo : t('to')}
          value={rangeTo}
          onChange={setRangeTo}
          InputAdornmentProps={{ position: 'start' }}
          inputFormat="DD.MM.YYYY"
          renderInput={(params) => (
            <Input
              {...params}
              color="primary"
              error={!!rangeFrom && !!rangeTo && rangeFrom > rangeTo}
            />
          )}
          disabled={!rangeFrom}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangeFilter
const Input = styled(TextField)`
  background-color: white;
  max-width: 300px;
`
