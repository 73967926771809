import gql from 'graphql-tag'

export interface IOrigoCard {
  id: string
  status: string
  userId: string
  cardNumber: number
  accessGranted: boolean
  deviceDescription: string
  privilegeRequested: boolean
  privilegeGranted: boolean
  origoAccount: {
    user: {
      login: string
    }
  }
}

export interface IQueryOrigoCardData {
  queryOrigoCard: IOrigoCard[]
}

export const QUERY_ORIGO_CARD = gql`
  query queryOrigoCard {
    queryOrigoCard {
      id
      status
      userId
      cardNumber
      accessGranted
      deviceDescription
      privilegeRequested
      privilegeGranted
      origoAccount: origo_account {
        user {
          login
        }
      }
    }
  }
`
