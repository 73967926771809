import { useState, FC, useEffect } from 'react'
import styled from 'styled-components'
import { TextField, InputAdornment } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { useTranslation } from 'react-i18next'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BaseButton } from '../Shared/BaseButton'
import { Button } from '@mui/material'

const AdvancedFilterInput: FC<{
  filterColumn: any
  columns: any
  setAllFilters: any
  setColumnFilter: any
}> = ({ filterColumn, setColumnFilter, columns, setAllFilters }) => {
  const { t } = useTranslation(['table_component'])
  const [searchValue, setSearchValue] = useState<string>(filterColumn[0]?.value)
  const [selectedColumn, setSelectedColumn] = useState<OptionType | null>(null)

  const handleSubmit = () => setColumnFilter(selectedColumn?.value, searchValue)

  const clearFilter = () => {
    setAllFilters([])
    setSearchValue('')
    setSelectedColumn(null)
  }

  useEffect(() => {
    !!filterColumn.length &&
      setSelectedColumn({
        value: filterColumn[0].id,
        label: 'Login',
      })
  }, [])

  return (
    <Wrapper>
      <SelectContainer data-e2e={'table-filter-column-select'}>
        <BaseSelect<OptionType>
          value={selectedColumn}
          onChange={(event) => setSelectedColumn(event)}
          options={columns.map(
            ({ accessor, Header }: { accessor: string; Header: string }) => ({
              value: accessor,
              label: Header,
            })
          )}
          noOptionsMessage={() => t('choose_column_no')}
          placeholder={t('choose_column')}
          isClearable={true}
        />
      </SelectContainer>
      <Input
        data-e2e={'table-filter-input'}
        value={searchValue || ''}
        onChange={(e) => {
          setSearchValue(e.target.value)
        }}
        label={t('enter_value')}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon
                icon={faSearch as IconProp}
                size="lg"
                color={themeColors.gray}
              />
            </InputAdornment>
          ),
        }}
      />

      <FilterButton
        onClick={handleSubmit}
        disabled={!searchValue || !selectedColumn}
        data-e2e={'table-filter-submit'}
      >
        {t('btn:search')}
      </FilterButton>
      <ClearButton onClick={clearFilter} data-e2e={'table-filter-clear'}>
        {t('btn:filter_clear')}
      </ClearButton>
    </Wrapper>
  )
}

const Input = styled(TextField)`
  width: 400px;
  margin: 0 16px;
`
const SelectContainer = styled.div`
  width: 250px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`
const FilterButton = styled(BaseButton)`
  margin: 0 8px;
  width: 110px;
  height: 56px;
`
const ClearButton = styled(Button)`
  text-transform: none;
  font-weight: 600;
`

export default AdvancedFilterInput
