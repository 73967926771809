import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useQuery, ApolloError } from '@apollo/client'
import {
  QUERY_ONE_TIME_PARKING_TICKET,
  IQueryOneTimeParkingTicketData,
  OneTimeParkingTicketStatus,
} from '../../graphql/parking/queryOneTimeParkingTicket'
import { ILog } from '../../graphql/shared/sharedTypes'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { getOneTimeTicketStatusColor } from '../../utils/getStatusColor'
import { formatToExcelPrice } from '../../utils/formatPrice'
import OneTimeTicketDetails from '../../components/OneTimeParkingTicket/OneTimeTicketDetails'
import ErrorContainer from '../../components/Shared/ErrorContainer'
interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'validFrom'
    | 'validTo'
    | 'validFromCSV'
    | 'validToCSV'
    | 'ticketNumber'
    | 'carPlate'
    | 'login'
    | 'email'
    | 'tenant'
    | 'status'
    | 'createdAt'
    | 'createdAtCSV'
    | 'parking'
    | 'price'
    | 'priceCSV'
}
export interface ITableRow {
  id: string
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV: string
  ticketNumber: string
  carPlate: string
  login: string
  email: string
  status: OneTimeParkingTicketStatus
  createdAt: string
  createdAtCSV: string
  parking: string
  price: string
  priceCSV: string
  logs: ILog[]
  tenant: string
  paymentId: string
}

const SoldOneTimeTicketList = () => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [error, setError] = useState('')
  const location = useLocation()
  const columnFilter: any = location?.state
  const lang = i18n.language

  const csvHeaderToDownload = [
    {
      label: t('valid_from'),
      key: 'validFromCSV',
    },
    {
      label: t('valid_to'),
      key: 'validToCSV',
    },
    {
      label: t('ticket_number'),
      key: 'ticketNumber',
    },
    {
      label: t('car_plate'),
      key: 'carPlate',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Tenant',
      key: 'tenant',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('purchase_date'),
      key: 'createdAtCSV',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: `${t('payment_amount')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const { data, loading, refetch } = useQuery<IQueryOneTimeParkingTicketData>(
    QUERY_ONE_TIME_PARKING_TICKET,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: t('valid_from'),
        accessor: 'validFrom',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('valid_to'),
        accessor: 'validTo',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('valid_from'),
        accessor: 'validFromCSV',
        isVisible: false,
      },
      {
        Header: t('valid_to'),
        accessor: 'validToCSV',
        isVisible: false,
      },
      {
        Header: t('ticket_number'),
        accessor: 'ticketNumber',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('car_plate'),
        accessor: 'carPlate',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'Parking',
        accessor: 'parking',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'Login',
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'Email',
        accessor: 'email',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
        isVisible: false,
      },
      {
        Header: t('ticket_status'),
        accessor: 'status',
        Cell: (props: { value: string }) =>
          t(`parking_ticket_status:${props.value}`),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('purchase_date'),
        accessor: 'createdAt',
        filter: 'filterRowsWithSubrows',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('purchase_date'),
        accessor: 'createdAtCSV',
        isVisible: false,
      },
      {
        Header: t('payment_amount'),
        accessor: 'price',
        filter: 'filterRowsWithSubrows',
        Cell: (props: { value: string }) => `${props.value} PLN`,
      },
      {
        Header: t('payment_amount'),
        accessor: 'priceCSV',
        isVisible: false,
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.tickets.map(
        ({
          id,
          status,
          ticketNumber,
          validFrom,
          validTo,
          createdAt,
          carPlate,
          user: { login, email, tenant },
          parkingName,
          price,
          logs,
          payment: { paymentId },
        }) => ({
          id,
          status,
          ticketNumber,
          validFrom,
          validTo,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validToCSV: dateIso2ExcelFormat(validTo),
          carPlate: carPlate,
          login,
          email: email?.email,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          parking: parkingName,
          price: (price * 0.01).toFixed(2),
          priceCSV: formatToExcelPrice(price),
          logs,
          tenant: tenant?.name,
          paymentId,
        })
      ) || []
    )
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('sold_one_time_tickets')}</Title>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        orderByProps="createdAt"
        csvExport
        csvHeaders={csvHeaderToDownload}
        loginFilterQuery={
          columnFilter?.columnFilter && columnFilter.columnFilter
        }
        onRowClick={(rowData: ITableRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getOneTimeTicketStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <OneTimeTicketDetails
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default SoldOneTimeTicketList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
