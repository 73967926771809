import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../RTTable'
import { IOrigoAccount } from '../../graphql/hid/queryOrigoAccount'
import { IOrigoCard } from '../../graphql/hid/queryOrigoCard'
import BaseDrawer from '../Shared/BaseDrawer'
import MobileAccessAccountDetails from './MobileAccessAccountDetails'

interface ITableColumns {
  Header: string
  accessor: 'login' | 'status' | 'groupId' | 'userId' | 'cards'
}
export interface ITableRow {
  id: string
  login: string
  status: string
  groupId: string
  userId: string
  cards: string
  origoCards: IOrigoCard[]
}

const MobileAccessAccountTable: FC<{
  data: IOrigoAccount[]
  refetch: () => void
}> = ({ data, refetch }) => {
  const { t } = useTranslation(['skd'])
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('columns:login'),
        accessor: 'login',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: t('columns:tenant'),
        accessor: 'groupId',
      },
      {
        Header: 'User Id',
        accessor: 'userId',
      },
      {
        Header: t('access_cards'),
        accessor: 'cards',
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data.map(
        ({ id, status, userId, origoCards, user: { tenant, login } }) => ({
          id,
          status,
          login,
          userId,
          groupId: tenant.name,
          cards: origoCards.map((item) => item.cardNumber).join(','),
          origoCards,
        })
      ) || [],
    [data]
  )

  return (
    <>
      <RTTable
        columns={tableColumns}
        data={tableData}
        onRowClick={(rowData) => setOpenedDetails(rowData)}
        pagination
        csvExport
        rowSelect
        orderByProps="login"
        sortDesc={false}
      />
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        {openedDetails && (
          <MobileAccessAccountDetails
            data={openedDetails}
            closeDrawer={() => setOpenedDetails(undefined)}
            refetch={refetch}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default MobileAccessAccountTable
