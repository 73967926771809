import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RTTable from '../../components/RTTable'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryEventOrdersData,
  QUERY_EVENT_ORDERS,
} from '../../graphql/events/queryEventOrders'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { Typography } from '@mui/material'
import { PaymentStatus } from '../../graphql/shared/sharedTypes'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import ErrorContainer from '../../components/Shared/ErrorContainer'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'createdAt'
    | 'createdAtCSV'
    | 'orderId'
    | 'residentTickets'
    | 'nonResidentTickets'
    | 'eventDate'
    | 'eventDateCSV'
    | 'eventName'
    | 'user'
    | 'email'
    | 'tenant'
    | 'service'
    | 'price'
    | 'priceCSV'
    | 'paymentStatus'
    | 'phoneNumber'
}
export interface ITableRow {
  id: string
  createdAt: string
  createdAtCSV: string
  orderId: string
  residentTickets: number
  nonResidentTickets: number
  eventDate: string
  eventDateCSV: string
  eventName: string
  user: string
  email: string
  tenant: string
  service: string
  price: string
  priceCSV: string
  paymentStatus: PaymentStatus
  phoneNumber?: string
}

const EventTicketList = () => {
  const { t, i18n } = useTranslation(['events'])
  const lang = i18n.language
  const [error, setError] = useState('')

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
    {
      label: t('order_id'),
      key: 'orderId',
    },
    {
      label: t('resident_tickets'),
      key: 'residentTickets',
    },
    {
      label: t('non_resident_tickets'),
      key: 'nonResidentTickets',
    },
    {
      label: t('event_date'),
      key: 'eventDateCSV',
    },
    {
      label: t('event_name'),
      key: 'eventName',
    },
    {
      label: t('user'),
      key: 'user',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: t('purchase_location'),
      key: 'service',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: t('payment_status'),
      key: 'paymentStatus',
    },
    {
      label: t('form:phone'),
      key: 'phoneNumber',
    },
  ]

  const { data, loading } = useQuery<IQueryEventOrdersData>(
    QUERY_EVENT_ORDERS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: t('created_at'),
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('created_at'),
        accessor: 'createdAtCSV',
        isVisible: false,
      },
      {
        Header: t('order_id'),
        accessor: 'orderId',
      },
      {
        Header: t('resident_tickets'),
        accessor: 'residentTickets',
      },
      {
        Header: t('non_resident_tickets'),
        accessor: 'nonResidentTickets',
      },
      {
        Header: t('event_date'),
        accessor: 'eventDate',
        Cell: (props: { value: string }) =>
          props.value && dateIso2localeString(props.value, lang),
      },
      {
        Header: t('event_date'),
        accessor: 'eventDateCSV',
        isVisible: false,
      },
      {
        Header: t('event_name'),
        accessor: 'eventName',
      },
      {
        Header: t('user'),
        accessor: 'user',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
      },
      {
        Header: t('purchase_location'),
        accessor: 'service',
      },
      {
        Header: t('price'),
        accessor: 'price',
      },
      {
        Header: t('price'),
        accessor: 'priceCSV',
        isVisible: false,
      },
      {
        Header: t('payment_status'),
        accessor: 'paymentStatus',
        Cell: (props: { value: string }) => t(`payment_status:${props.value}`),
      },
      {
        Header: t('form:phone'),
        accessor: 'phoneNumber',
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.queryEventOrders.map(
        ({
          id,
          orderId,
          createdAt,
          paymentId,
          user: { firstName, lastName, email, tenant },
          service,
          event,
          tickets,
          amount,
          paymentStatus,
          phoneNumber,
        }) => ({
          id,
          orderId,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          residentTickets: tickets.filter(({ resident }) => resident).length,
          nonResidentTickets: tickets.filter(({ resident }) => !resident)
            .length,
          eventDate: event?.startDate && event.startDate,
          eventDateCSV:
            event?.startDate && dateIso2ExcelFormat(event.startDate),
          eventName: event?.title ? event.title : 'brak',
          user: `${firstName} ${lastName}`,
          email: email?.email,
          tenant: !!tenant?.name ? tenant.name : 'brak',
          service,
          price: `${amount.toFixed(2)} PLN`,
          priceCSV: `${amount.toFixed(2).replace('.', ',')}`,
          paymentStatus: paymentStatus.toLocaleLowerCase() as PaymentStatus,
          phoneNumber: phoneNumber || 'brak',
        })
      ) || []
    )
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        csvExport
        orderByProps="createdAt"
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'paymentStatus'
            ? {
                style: {
                  color: getPaymentStatusColor(row.original.paymentStatus),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default EventTicketList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
