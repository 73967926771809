import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import RTTable from '../../components/RTTable'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import styled from 'styled-components'
import {
  DELETE_ACCOUNT_REQUESTS_REPORT,
  IDeleteAccountRequestsReportData,
} from '../../graphql/userIssues/deleteAccountRequestsReport'
import { Typography } from '@mui/material'
import { getAccountStatusColor } from '../../utils/getStatusColor'

interface ITableColumns {
  Header: string
  accessor: 'date' | 'dateCSV' | 'resident' | 'tenant' | 'accountStatus'
}
export interface ITableRow {
  date: string
  dateCSV: string
  resident: boolean
  tenant: string
  accountStatus: string
}

const DeleteAccountRequestsList = () => {
  const { t, i18n } = useTranslation(['columns', 'admin_menu'])
  const lang = i18n.language

  const { data, loading } = useQuery<IDeleteAccountRequestsReportData>(
    DELETE_ACCOUNT_REQUESTS_REPORT,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const csvHeaders = [
    {
      label: t('creation_date'),
      key: 'dateCSV',
    },
    {
      label: t('resident'),
      key: 'resident',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: t('account_status'),
      key: 'accountStatus',
    },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('creation_date'),
        accessor: 'date',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('creation_date'),
        accessor: 'dateCSV',
        isVisible: false,
      },
      {
        Header: t('resident'),
        accessor: 'resident',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
      },
      {
        Header: t('account_status'),
        accessor: 'accountStatus',
        Cell: (props: { value: string }) => t(`account_status:${props.value}`),
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data?.queryIssue.map(
        ({ createdAt, user: { tenant, disabled, anonymizeStatus } }) => ({
          date: createdAt,
          dateCSV: dateIso2ExcelFormat(createdAt),
          resident: !!tenant?.name ? t('bool:yes') : t('bool:no'),
          tenant: tenant?.name || '-',
          accountStatus: disabled
            ? 'disabled'
            : !!anonymizeStatus
            ? 'anonimized'
            : 'active',
        })
      ) || [],
    [data, t]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:delete_account_requests')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        pagination
        csvExport
        rowSelect
        orderByProps="createdAt"
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'accountStatus'
            ? {
                style: {
                  color: getAccountStatusColor(row.original.accountStatus),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
    </>
  )
}

export default DeleteAccountRequestsList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
