import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import { IFormError } from '../../types/FormTypes'

const TimePickerController = ({
  label,
  control,
  errors,
  name,
  defaultValue,
  rules,
  disabled,
}: {
  label: string
  control: any
  errors: IFormError | any
  name: string
  defaultValue?: Date | null
  rules?: Object
  disabled?: boolean
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <TimePicker
          mask="__:__"
          label={label}
          value={value}
          onChange={onChange}
          InputAdornmentProps={{ position: 'start' }}
          //   inputFormat="DD.MM.YYYY"
          disabled={disabled}
          renderInput={(params) => (
            <Input {...params} color="primary" error={!!errors[name]} />
          )}
        />
      )}
    />
  )
}

export default TimePickerController

const Input = styled(TextField)`
  background-color: white;
  max-width: 300px;
`
