import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold, TextBody1 } from '../Styles/TextCustom'
import DrawerHeader from '../Shared/DrawerHeader'
import { ITableRow } from './MobileAccessAccountTable'
import AccessCard from './AccessCard'
import DeleteMobileAccessAccount from './DeleteMobileAccessAccount'

const MobileAccessAccountDetails: FC<{
  data: ITableRow
  closeDrawer: () => void
  refetch: () => void
}> = ({ data, closeDrawer, refetch }) => {
  const { t } = useTranslation(['skd'])
  const { login, groupId, origoCards, userId } = data

  const accountData = [
    { label: 'Login', value: login },
    { label: t('company'), value: groupId },
    { label: 'User ID', value: userId },
  ]

  return (
    <>
      <DrawerHeader
        title={t('access_account_details')}
        handleClose={closeDrawer}
      />
      <Grid>
        {accountData.map(
          (item) =>
            !!item.value && (
              <Row key={item.label}>
                <DetailText>{`${item.label}: `}</DetailText>
                <DetailBold>{item.value}</DetailBold>
              </Row>
            )
        )}
      </Grid>
      <TextTitle>
        {!!origoCards.length ? `${t('access_cards')}:` : t('no_cards')}
      </TextTitle>
      {origoCards.map((item) => (
        <AccessCard data={item} key={item.id} />
      ))}
      <DeleteMobileAccessAccount data={data} refetch={refetch} />
    </>
  )
}

export default MobileAccessAccountDetails

const TextTitle = styled(TextBody1)`
  font-weight: 600;
`
const Row = styled(Grid)`
  padding: 4px 6px;
`
const DetailText = styled(TextBody2Medium)`
  display: inline;
`
const DetailBold = styled(TextBody2Bold)`
  display: inline;
`
