import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../RTTable'
import BaseDrawer from '../Shared/BaseDrawer'
import EditParking from './EditParking'
import {
  GET_PARKING_LIST,
  IParkingListData,
} from '../../graphql/parking/getParkingList'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { ParkingPlaceStatus } from '../../graphql/parking/queryParkingPlace'

interface ITableColumns {
  Header: string
  accessor:
    | 'buildings'
    | 'parkingBuildings'
    | 'capacity'
    | 'dedicatedPlaces'
    | 'id'
    | 'name'
    | 'placesNumber'
    | 'remarks'
    | 'sellerName'
    | 'sellerEmail'
}
export interface ITableRow {
  buildings?: string
  capacity?: number
  dedicatedPlaces?: number
  id: string
  name: string
  placesNumber: number
  places: {
    name: string
    id: string
  }[]
  remarks?: string
  parkingBuildings: { name: string; value: boolean }[]
  sellerName: string
  sellerEmail: string
  sellerId: string
}

const ParkingsListTable = () => {
  const { t } = useTranslation(['parking_subscriptions_products_list'])
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()

  const csvHeaders = [
    {
      label: t('id'),
      key: 'id',
    },
    {
      label: 'Parking',
      key: 'name',
    },
    {
      label: t('parking_list:limit_place'),
      key: 'capacity',
    },
    {
      label: t('parking_list:is_dedicate_places'),
      key: 'dedicatedPlaces',
    },
    {
      label: t('parking_list:dedicate_places_number'),
      key: 'placesNumber',
    },
    {
      label: t('parking_list:parking_building'),
      key: 'buildings',
    },
    {
      label: t('parking_list:company'),
      key: 'sellerName',
    },
    {
      label: t('parking_list:company_email'),
      key: 'sellerEmail',
    },
    {
      label: t('parking_list:remarks'),
      key: 'remarks',
    },
  ]

  const { data, refetch, loading } = useQuery<IParkingListData>(
    GET_PARKING_LIST,
    {
      fetchPolicy: 'network-only',
    }
  )

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
        isVisible: false,
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_list:name'),
        accessor: 'name',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_list:limit_place'),
        accessor: 'capacity',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_list:parking_building'),
        accessor: 'buildings',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_list:company'),
        accessor: 'sellerName',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_list:company_email'),
        accessor: 'sellerEmail',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('parking_list:remarks'),
        accessor: 'remarks',
        filter: 'filterRowsWithSubrows',
      },
    ],
    [t]
  )

  const tableData: ITableRow[] = useMemo(
    () =>
      data?.queryParking.map(
        ({
          buildings,
          capacity,
          dedicatedPlaces,
          id,
          name,
          places,
          remarks,
          seller,
        }) => {
          const responseData: any = {
            buildings: buildings.map(({ name }) => name).join(', '),
            capacity: places.filter(
              ({ status }) => status === ParkingPlaceStatus.available
            ).length,
            dedicatedPlaces,
            placesNumber: places.length,
            places,
            id,
            name,
            sellerName: seller?.sellerName,
            sellerEmail: seller?.sellerEmail,
            sellerId: seller?.sellerId,
            remarks,
          }

          return responseData
        }
      ) || [],

    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        orderByProps="name"
        sortDesc={false}
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData) => {
          rowData.name && setOpenedDetails(rowData)
        }}
      />
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditParking
          closeDetails={() => setOpenedDetails(undefined)}
          updateList={refetch}
          parking={openedDetails}
        />
      </BaseDrawer>
    </>
  )
}

export default ParkingsListTable
