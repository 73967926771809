import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
  date2isoString,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useLazyQuery, ApolloError } from '@apollo/client'
import {
  QUERY_TENANT_PARKING_POOL_SUBSCRIPTIONS,
  IQueryTenantParkingPoolSubscriptionsVars,
  IQueryTenantParkingPoolSubscriptionsData,
} from '../../graphql/parking/queryTenantParkingPoolSubscriptions'
import {
  ParkingSubscriptionStatus,
  IParkingTicket,
  ISubscription,
} from '../../graphql/parking/queryParkingSubscriptions'
import {
  GET_TENANT_PARKING_POOL_REPORT,
  IGetTenantParkingPoolReportVars,
  IGetTenantParkingPoolReportData,
} from '../../graphql/parking/queryTenantParkingPoolReport'
import { ILog } from '../../graphql/shared/sharedTypes'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import SubscriptionDetails from '../../components/Parking/SubscriptionDetails'
import { getSubscriptionStatusColor } from '../../utils/getStatusColor'
import { formatToExcelPrice } from '../../utils/formatPrice'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import ParkingPoolReservationsFilter from '../../components/Parking/ParkingPoolReservationsFilter'

interface ITableColumns {
  Header: string
  accessor:
    | 'id'
    | 'validFrom'
    | 'validTo'
    | 'validFromCSV'
    | 'validToCSV'
    | 'durationDays'
    | 'login'
    | 'email'
    | 'tenant'
    | 'createdAt'
    | 'createdAtCSV'
    | 'carPlate'
    | 'carPlate2'
    | 'status'
    | 'priceCSV'
    | 'parking'
    | 'amanoCardNo'
}
export interface ITableRow {
  id: string
  status: ParkingSubscriptionStatus
  validFrom: string
  validTo: string
  validFromCSV: string
  validToCSV: string
  createdAt: string
  createdAtCSV: string
  carPlate: string
  carPlate2: string
  carPlates: string
  productName: string
  durationDays: number
  login: string
  email: string
  logs: ILog[]
  tickets: IParkingTicket[]
  tenant: string
  priceCSV?: string
  parking: string
  amanoCardNo: number
  operator: string
}

const now = new Date(new Date().setHours(0, 0, 0, 0))

const ActiveSubscriptionsList = () => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const [minValidTo, setMinValidTo] = useState<Date | null>(now)
  const [openedDetails, setOpenedDetails] = useState<ITableRow>()
  const [tenant, setTenant] = useState<string | null>(null)
  const [items, setItems] = useState<ISubscription[]>([])
  const [error, setError] = useState('')
  const location = useLocation()
  const columnFilter: any = location?.state
  const lang = i18n.language

  const days = (date1: Date, date2: Date) => {
    const difference = date1.getTime() - date2.getTime()
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24))
    return totalDays
  }

  const csvHeaders = [
    {
      label: t('valid_from'),
      key: 'validFromCSV',
    },
    {
      label: t('valid_to'),
      key: 'validToCSV',
    },
    {
      label: t('duration'),
      key: 'durationDays',
    },
    {
      label: t('car_plate'),
      key: 'carPlate',
    },
    {
      label: `${t('car_plate')} 2`,
      key: 'carPlate2',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('purchase_date'),
      key: 'createdAtCSV',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: `${t('payment_value')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: 'AMANO numer biletu',
      key: 'amanoCardNo',
    },
  ]

  const [querySubscriptions, { loading, refetch }] = useLazyQuery<
    IQueryTenantParkingPoolSubscriptionsData,
    IQueryTenantParkingPoolSubscriptionsVars
  >(QUERY_TENANT_PARKING_POOL_SUBSCRIPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: IQueryTenantParkingPoolSubscriptionsData) =>
      setItems(data.subscriptions),
    onError: (error: ApolloError) => setError(error.message),
  })

  const [getReport, { loading: getReportLoading }] = useLazyQuery<
    IGetTenantParkingPoolReportData,
    IGetTenantParkingPoolReportVars
  >(GET_TENANT_PARKING_POOL_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: IGetTenantParkingPoolReportData) =>
      setItems(
        data.queryParkingTicket.map(({ subscription }) => subscription) || []
      ),
    onError: (error: ApolloError) => setError(error.message),
  })

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        isVisible: false,
      },
      {
        Header: t('valid_from'),
        accessor: 'validFrom',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('valid_from'),
        accessor: 'validFromCSV',
        isVisible: false,
      },
      {
        Header: t('valid_to'),
        accessor: 'validTo',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('valid_to'),
        accessor: 'validToCSV',
        isVisible: false,
      },
      {
        Header: t('duration'),
        accessor: 'durationDays',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('car_plate'),
        accessor: 'carPlate',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: `${t('car_plate')} 2`,
        accessor: 'carPlate2',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'Login',
        accessor: 'login',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: 'Email',
        accessor: 'email',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: (props: { value: string }) =>
          t(`parking_subscription_status:${props.value}`),
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('purchase_date'),
        accessor: 'createdAt',
        filter: 'filterRowsWithSubrows',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('purchase_date'),
        accessor: 'createdAtCSV',
        isVisible: false,
      },
      {
        Header: 'Parking',
        accessor: 'parking',
        filter: 'filterRowsWithSubrows',
      },
      {
        Header: t('payment_value'),
        accessor: 'priceCSV',
        isVisible: false,
      },
      {
        Header: 'AMANO numer biletu',
        accessor: 'amanoCardNo',
        isVisible: false,
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      items?.map(
        ({
          id,
          status,
          validFrom,
          validTo,
          createdAt,
          parkingPass: { name, nameEn, parking },
          user: { login, email, tenant },
          logs,
          tickets,
          activeParkingTicket,
          pendingParkingTicket,
          amanoCardNo,
        }) => ({
          id,
          validFrom,
          validTo,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validToCSV: dateIso2ExcelFormat(validTo),
          carPlate: tickets[0]?.carPlate || '',
          carPlate2: tickets[0].carPlate2 || '',
          carPlates: `${tickets[0]?.carPlate}${
            tickets[0].carPlate2 ? `, ${tickets[0].carPlate2}` : ''
          }`,
          productName: lang === 'pl' ? name : nameEn,
          durationDays: days(new Date(validTo), new Date(validFrom)),
          login,
          email: email?.email,
          status,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          logs,
          tickets,
          tenant: tenant.name,
          parking: parking.name,
          priceCSV: activeParkingTicket
            ? formatToExcelPrice(activeParkingTicket?.payment?.amount)
            : pendingParkingTicket
            ? formatToExcelPrice(pendingParkingTicket?.payment?.amount)
            : '',
          amanoCardNo,
          operator: parking.operator,
        })
      ) || []
    )
  }, [items, lang])

  useEffect(() => {
    if (minValidTo) {
      !tenant
        ? querySubscriptions({
            variables: { minValidTo: date2isoString(minValidTo) },
          })
        : getReport({
            variables: {
              parkingPoolId: tenant,
              minValidTo: date2isoString(minValidTo),
            },
          })
    }
  }, [minValidTo, tenant])

  if (loading || getReportLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('current_reservations_list')}</Title>
      <ParkingPoolReservationsFilter
        minValidTo={minValidTo}
        setMinValidTo={setMinValidTo}
        tenant={tenant}
        setTenant={setTenant}
      />
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        csvExport
        csvHeaders={csvHeaders}
        orderByProps="validTo"
        sortDesc={false}
        loginFilterQuery={
          columnFilter?.columnFilter && columnFilter.columnFilter
        }
        onRowClick={(rowData: ITableRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'status'
            ? {
                style: {
                  color: getSubscriptionStatusColor(row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <SubscriptionDetails
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default ActiveSubscriptionsList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
