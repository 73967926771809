import React from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  QUERY_ORIGO_ACCOUNT,
  IQueryOrigoAccountData,
} from '../../graphql/hid/queryOrigoAccount'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import MobileAccessAccountTable from '../../components/HID/MobileAccessAccountTable'

const MobileAccessAccountList = () => {
  const { t } = useTranslation(['skd'])

  const { data, loading, refetch } =
    useQuery<IQueryOrigoAccountData>(QUERY_ORIGO_ACCOUNT)

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('access_account_list')}
      </TypographyStyled>
      <MobileAccessAccountTable
        data={data?.queryOrigoAccount}
        refetch={refetch}
      />
    </>
  )
}

export default MobileAccessAccountList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
