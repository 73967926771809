import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  useTable,
  useGlobalFilter,
  TableOptions,
  useSortBy,
  useRowSelect,
  Hooks,
  Cell,
  usePagination,
  useFilters,
} from 'react-table'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Checkbox,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material'
import GlobalFilterInput from '../components/Shared/GlobalFilterInput'
import { useTranslation } from 'react-i18next'
import TableCSVLink from '../components/Table/TableCSVLink'
import AdvancedColumnFilter from '../components/Table/AdvancedColumnFilter'
interface IRTTableProps<T extends object> extends TableOptions<T> {
  rowSelect?: boolean
  onSelectionChange?: (data: T[]) => void
  pagination?: boolean
  onRowClick?: (rowData: T) => void
  actionsComponent?: (rowData: T) => React.ReactNode
  getCellProps?: (cell: Cell<T, any>) => object
  getRowProps?: (rowData: T) => object
  gFilter?: boolean
  csvExport?: boolean
  orderByProps?: string
  sortDesc?: boolean
  csvHeaders?: { label: string; key: string }[] | string[]
  filterDownloadCsvValue?: string
  filterDownloadCsvKeys?: string
  loginFilter?: string
  isFiltersOff?: boolean
  csvFileName?: string
  children?: React.ReactNode
}

const defaultPropGetter = (cell: any) => ({})
const defaultRowPropGetter = (row: any) => ({})

/**
 * RTTable - React-Table
 * @param rowSelect Adds checkboxes to table rows.
 * @param onSelectionChange Needs to be wrapped in useCallBack to prevent infinite loop.
 * @param csvExport Displays export button. Enable also "rowSelect" to be able to export csv file.
 */

const RTTable = <T extends object>({
  columns,
  data,
  rowSelect,
  onSelectionChange,
  pagination,
  onRowClick,
  actionsComponent,
  getCellProps = defaultPropGetter,
  getRowProps = defaultRowPropGetter,
  gFilter = true,
  hiddenColumns = [],
  csvExport,
  orderByProps = '',
  sortDesc = true,
  csvHeaders,
  filterDownloadCsvValue,
  filterDownloadCsvKeys,
  loginFilter,
  isFiltersOff = false,
  csvFileName,
  children,
}: IRTTableProps<T>) => {
  const { t } = useTranslation('table_component')
  const selectionColumnId = 'selection'
  const actionColumnId = 'action'
  const getCellPadding = (columnId: string) =>
    columnId === selectionColumnId
      ? 'checkbox'
      : columnId === actionColumnId
      ? 'none'
      : undefined

  const customHooks = (hooks: Hooks<T>) => {
    rowSelect &&
      hooks.visibleColumns.push((columns) => [
        {
          id: selectionColumnId,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: any) => (
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              onClick={(e) => e.stopPropagation()}
            />
          ),
        },
        ...columns,
      ])

    actionsComponent &&
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: actionColumnId,
          Cell: ({ row }: any) => <>{actionsComponent(row.original)}</>,
        },
      ])
  }

  const filterTypes = React.useMemo(
    () => ({
      filterRowsWithSubrows: (rows: any, id: any, filterValue: string) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase() ===
                String(filterValue).toLowerCase()
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, filters },
    setGlobalFilter,
    setHiddenColumns,
    setFilter,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns,
        pageSize: 20,
        filters: loginFilter ? [{ id: 'login', value: loginFilter }] : [],
        sortBy: [{ id: orderByProps, desc: sortDesc }],
      },
      filterTypes,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    customHooks
  )

  useEffect(() => {
    setHiddenColumns(
      columns
        .filter((column) => column.isVisible === false)
        .map((column) => column.accessor as string)
    )
    onSelectionChange &&
      onSelectionChange(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows, onSelectionChange, columns, setHiddenColumns])

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {gFilter && (
            <GlobalFilterInput
              filter={globalFilter}
              setFilter={setGlobalFilter}
            />
          )}
        </Grid>
        <Grid item>
          {csvExport && (
            <TableCSVLink
              csvHeaders={csvHeaders}
              fileName={csvFileName}
              data={selectedFlatRows}
              filterDownloadCsvValue={filterDownloadCsvValue}
              filterDownloadCsvKeys={filterDownloadCsvKeys}
            />
          )}
        </Grid>
      </Grid>
      {!isFiltersOff && (
        <AdvancedColumnFilter
          filters={filters}
          setFilters={setFilter}
          headers={columns.filter((column) => column.isVisible === undefined)}
          setAllFilters={setAllFilters}
          login={loginFilter}
        />
      )}
      {children}
      <TableContainer>
        <TableStyled {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps()}
                      padding={getCellPadding(column.id)}
                    >
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          column.id !== 'selection' &&
                            (column.isSortedDesc
                              ? column.toggleSortBy(false, false)
                              : column.toggleSortBy(true, false))
                        }}
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {(pagination ? page : rows).map((row) => {
              prepareRow(row)
              return (
                <TableRowStyled
                  {...row.getRowProps([getRowProps(row.original)])}
                  hover
                  selected={row.isSelected}
                  isClickable={!!onRowClick}
                  onClick={() => onRowClick && onRowClick(row.original)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps([getCellProps(cell)])}
                        padding={getCellPadding(cell.column.id)}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRowStyled>
              )
            })}
          </TableBody>
          <TableFooter>
            {footerGroups.map((group) => (
              <TableRow {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <CellStyled {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </CellStyled>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        </TableStyled>
      </TableContainer>

      {pagination && (
        <TablePagination
          component="div"
          count={rows.length}
          page={pageIndex}
          onPageChange={(e, page) => gotoPage(page)}
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => setPageSize(Number(e.target.value))}
        />
      )}
    </>
  )
}

export default RTTable

const TableRowStyled = styled(({ isClickable, ...props }) => (
  <TableRow {...props} />
))<{ isClickable?: boolean }>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
`
const TableStyled = styled(Table)`
  overflow-x: scroll;
`
const CellStyled = styled(TableCell)`
  font-size: 16px;
  font-weight: 600;
`
