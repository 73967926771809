import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useQuery } from '@apollo/client'
import {
  QUERY_CAR_PLATE_VERIFICATION,
  IQueryCarPlateVerificationData,
} from '../../graphql/parking/queryCarPlateVerification'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import ErrorContainer from '../../components/Shared/ErrorContainer'
interface ITableColumns {
  Header: string
  accessor: string
}

export interface ITableRow {
  id: string
  createdAt: string
  createdAtCSV: string
  user: string
  carPlate: string
  found: boolean
}

const CarPlateVerificationReport = () => {
  const { t, i18n } = useTranslation(['parking'])
  const lang = i18n.language
  const [error, setError] = useState('')

  const csvHeaders = [
    {
      label: 'Data',
      key: 'createdAtCSV',
    },
    {
      label: 'Kontroler',
      key: 'user',
    },
    {
      label: 'Tablica',
      key: 'carPlate',
    },
    {
      label: 'Bilet',
      key: 'found',
    },
  ]

  const { data, loading } = useQuery<IQueryCarPlateVerificationData>(
    QUERY_CAR_PLATE_VERIFICATION,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const tableColumns: ITableColumns[] | any = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', isVisible: false },
      {
        Header: t('payment_date_csv'),
        accessor: 'createdAtCSV',
        isVisible: false,
      },
      {
        Header: 'Data',
        accessor: 'createdAt',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('controller'),
        accessor: 'user',
      },
      {
        Header: t('car_plate'),
        accessor: 'carPlate',
      },
      {
        Header: t('ticket'),
        accessor: 'found',
        Cell: (props: { value: boolean }) =>
          props.value ? t('bool:yes') : t('bool:no'),
      },
    ],
    [t, lang]
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.queryCarPlateVerification?.map(
        ({
          id,
          createdAt,
          carPlate,
          user: { firstName, lastName, login },
          found,
        }) => ({
          id,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          carPlate,
          user: login,
          found,
        })
      ) || []
    )
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:car_plate_verification_report')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect
        pagination
        orderByProps="createdAt"
        csvExport
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'found' && !!row.original.found
            ? {
                style: {
                  color: themeColors.accept,
                  fontWeight: 600,
                },
              }
            : {}
        }
      />
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default CarPlateVerificationReport

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
