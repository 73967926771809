import styled from 'styled-components'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@mui/material'
import { themeColors } from '../../const/colors'
import { Link } from 'react-router-dom'

export const ListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`
export const MainListItem = styled(ListItemButton)`
  min-height: 4rem;
`
export const ListItem = styled(ListItemButton)`
  padding: 8px 16px 8px 70px;
`
export const CustomListItemText = styled(ListItemText)`
  padding-right: 4.4rem;
  color: ${themeColors.black};
`
export const ListItemTextStyled = styled(ListItemText)<{ rpadding?: number }>`
  padding-right: ${(props) => (!!props.rpadding ? props.rpadding : 65)}px;
`
export const ListItemIconStyled = styled(ListItemIcon)``

export const BadgeStyled = styled(Badge)`
  & .MuiBadge-badge {
    top: 10%;
  }
`
export const BadgeDot = styled(BadgeStyled)`
  & .MuiBadge-badge {
    top: 30%;
    right: 25%;
  }
`
export const LinkStyled = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${themeColors.black};
`
